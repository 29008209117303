import React from "react";
import "./ElectricalProducts.css";
import TerminalB from "../../../Assets/Images/Terminal Blocks.png";
import FastenerF from "../../../Assets/Images/Fasteners And Fixings.png";
import ElectricalA from "../../../Assets/Images/Electrical Accessories.png";
import Img3 from "../../../Assets/Images/img3.png";
import CableG from "../../../Assets/Images/Cables And Gland (1).png";
import Earthing from "../../../Assets/Images/Earthing Accessories.png";
import CableT from "../../../Assets/Images/NewCableTies.png";
import CastingP from "../../../Assets/Images/Casting Product.png";
import MetalE from "../../../Assets/Images/Metal Enclosure.png";
import { Link } from "react-router-dom";
import HomeVideo from "../../../Assets/Images/Video/Energy.mp4";
import ReactPlayer from "react-player";

function ElectricalProducts() {
  return (
    <>
      <div className="video-container">
        <ReactPlayer
          url={HomeVideo}
          width="100%"
          height="100%"
          controls={false}
          playing
          loop
          muted
        />
      </div>
      <section className="product-section ">
        <div className="container">
          <div className="row justify-content-center product-row">
            <h1 className=" mt-5 mb-4">Energy</h1>
            <div className="col-md-4 my-4">
              <div className="card" style={{ width: "22rem" }}>
                <img
                  src={TerminalB}
                  className="card-img-top"
                  alt="TerminalImg"
                />
                <Link to="/terminal-blocks">
                  <div className="arrow">
                    <span>
                      <i className="fa-solid fa-arrow-right fa-beat "></i>
                    </span>
                  </div>
                </Link>
                <div className="card-body"></div>
                <Link to="/terminal-blocks" className="card-button">
                  Terminal Block
                </Link>
              </div>
            </div>
            <div className="col-md-4 my-4">
              <div className="card" style={{ width: "22rem" }}>
                <img src={FastenerF} className="card-img-top" alt="image" />
                <Link to="/fastener-fixing">
                  <div className="arrow">
                    <span>
                      <i className="fa-solid fa-arrow-right fa-beat "></i>
                    </span>
                  </div>
                </Link>
                <div className="card-body"></div>
                <Link to="/fastener-fixing" className="card-button">
                  Fasteners And Fixings
                </Link>
              </div>
            </div>
            <div className="col-md-4 my-4">
              <div className="card" style={{ width: "22rem" }}>
                <img src={ElectricalA} className="card-img-top" alt="image" />
                <Link to="/electrical-accessories">
                  <div className="arrow">
                    <span>
                      <i className="fa-solid fa-arrow-right fa-beat "></i>
                    </span>
                  </div>
                </Link>
                <div className="card-body"></div>
                <Link to="/electrical-accessories" className="card-button">
                  Electrical Accessories
                </Link>
              </div>
            </div>
            <div className="col-md-4 my-4">
              <div className="card" style={{ width: "22rem" }}>
                <img src={Img3} className="card-img-top" alt="image" />
                <Link to="/brass-electrical-accessories">
                  <div className="arrow">
                    <span>
                      <i className="fa-solid fa-arrow-right fa-beat "></i>
                    </span>
                  </div>
                </Link>
                <div className="card-body"></div>
                <Link
                  to="/brass-electrical-accessories"
                  className="card-button"
                >
                  Brass Electrical Accessories
                </Link>
              </div>
            </div>{" "}
            <div className="col-md-4 my-4">
              <div className="card" style={{ width: "22rem" }}>
                <img src={CableG} className="card-img-top" alt="image" />
                <Link to="/CableTies">
                  <div className="arrow">
                    <span>
                      <i className="fa-solid fa-arrow-right fa-beat "></i>
                    </span>
                  </div>
                </Link>
                <div className="card-body"></div>
                <Link to="/CableTies" className="card-button">
                  Cables And Gland
                </Link>
              </div>
            </div>
            <div className="col-md-4 my-4">
              <div className="card" style={{ width: "22rem" }}>
                <img src={Earthing} className="card-img-top" alt="image" />
                <Link to="/coppper-brass-earthing-accessories">
                  <div className="arrow">
                    <span>
                      <i className="fa-solid fa-arrow-right fa-beat "></i>
                    </span>
                  </div>
                </Link>
                <div className="card-body"></div>
                <Link
                  to="/coppper-brass-earthing-accessories"
                  className="card-button"
                >
                  Earthing Accessories
                </Link>
              </div>
            </div>{" "}
            <div className="col-md-4 my-4">
              <div className="card" style={{ width: "22rem" }}>
                <img src={CableT} className="card-img-top" alt="image" />
                <Link to="/CableTies2">
                  <div className="arrow">
                    <span>
                      <i className="fa-solid fa-arrow-right fa-beat "></i>
                    </span>
                  </div>
                </Link>
                <div className="card-body"></div>
                <Link to="/CableTies2" className="card-button">
                  Cable Ties
                </Link>
              </div>
            </div>{" "}
            <div className="col-md-4 my-4">
              <div className="card" style={{ width: "22rem" }}>
                <img src={CastingP} className="card-img-top" alt="image" />
                <Link to="/casting-product">
                  <div className="arrow">
                    <span>
                      <i className="fa-solid fa-arrow-right fa-beat "></i>
                    </span>
                  </div>
                </Link>
                <div className="card-body"></div>
                <Link to="/casting-product" className="card-button">
                  Casting Product
                </Link>
              </div>
            </div>{" "}
            <div className="col-md-4 my-4">
              <div className="card" style={{ width: "22rem" }}>
                <img src={MetalE} className="card-img-top" alt="image" />
                <Link to="/metal-enclosure-component">
                  <div className="arrow">
                    <span>
                      <i className="fa-solid fa-arrow-right fa-beat "></i>
                    </span>
                  </div>
                </Link>
                <div className="card-body"></div>
                <Link to="/metal-enclosure-component" className="card-button">
                  Metal Enclosure
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ElectricalProducts;
