import React from "react";
import "./InnerFastener.css";
import { Link, useParams } from "react-router-dom";
import ProductData from "../../../../../Products.json";

const InnerFastener = () => {
  const { typeId } = useParams();

  const fastnerSection = ProductData.sections.find(
    (section) => section.id == 2
  );

  const relevantData = fastnerSection.products[1] || {};
  const InsideRelevent = relevantData.insideProduct.filter(
    (item) => item.type_id == typeId
  );

  let products = InsideRelevent[0].subType[0].products;

  return (
    <div>
      {InsideRelevent[0].subType.map((item) => {
        return (
          <>
            <section className="Inner-fastener-Section">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="Inner-fastener-subheader-content">
                      <h1>{item.type_name}</h1>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="Inn-fas-section2">
              <div className="container">
                <div className="product">
                  <h3 className="fw-600">{item.type_name}</h3>
                  <p>{item.desc}</p>
                  {item.Material && (
                    <p>
                      <strong> Material: </strong> {item.Material}
                    </p>
                  )}
                  {item.Finish && (
                    <p>
                      <strong>Finish: </strong>
                      {item.Finish}
                    </p>
                  )}
                  {item.Threads && (
                    <p>
                      <strong>Threads: </strong> {item.Threads}
                    </p>
                  )}
                  {item.Sizes && (
                    <p>
                      <strong>Sizes: </strong> {item.Sizes}
                    </p>
                  )}
                  {item.LengthSize && (
                    <p>
                      <strong>Length / Sizes: </strong> {item.LengthSize}
                    </p>
                  )}
                </div>
              </div>
            </section>
          </>
        );
      })}

      <section>
        <div className="container">
          <div className="row" style={{ rowGap: "22px" }}>
            {products.map((item, index) => (
              <div key={index} className="col-lg-3 col-md-6">
                <div className="service-block-3 mb-xl-30">
                  <div className="PCard">
                    <div className="service-image animate-img">
                      <img
                        src={item.image}
                        alt={item.type_name}
                        className="inner-fast-img"
                      />
                    </div>

                    <div className="card-body">
                      <h4 className="card-title">{item.type_name}</h4>

                      <Link
                        to={`/fastner-subinner/${typeId}/${item.type_id}`}
                        className="seview"
                      >
                        <button>View Details</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default InnerFastener;
