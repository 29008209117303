import React, { useEffect, useState } from "react";
import "./InnerCastingProduct.css";
import ProductData from "../../../../../Products.json";
import { useParams, Link } from "react-router-dom";

const InnerCastingProduct = () => {
  const [loading, setLoading] = useState(true);
  const { typeId } = useParams();

  const AccessrSection = ProductData.sections.find(
    (section) => section.id == 2
  );

  // Extract the relevant data from the found section
  const relevantData =
    AccessrSection.products[7] ||
    [].find((item) => item.id === 208).EarthingProduct;
  const CastingProducts = relevantData.CastingProduct;

  const Products = CastingProducts.filter((product) => product.id == typeId);
  const filteredProduct = Products[0].innerProduct;
  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <div>
      <section className="InnerCasting-Section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="casting-subheader-content">
                <h1>{Products[0].name}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row" style={{ rowGap: "20px" }}>
            <h3 className="my-5">{Products[0].name}</h3>
            {filteredProduct.map((item, index) => (
              <div key={index} className="col-lg-3 col-md-6">
                <div className="service-block-3 mb-xl-30">
                  <div className="PCard">
                    <div className="service-image animate-img">
                      <img
                        src={item.image}
                        alt={item.type_name}
                        className="inner-fast-img"
                        onLoad={handleImageLoad}
                      />
                    </div>
                    {loading && <div className="loader"></div>}

                    <div className="card-body">
                      <h4 className="card-title">{item.type_name}</h4>

                      <Link
                        to={`/sub-innner-casting-product/${typeId}/${item.type_id}`}
                        className="seview"
                      >
                        <button>View Details</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default InnerCastingProduct;
