import React from "react";
//
import errorani from "../../Assets/Images/ErrorPageAnimation/ErrorAnimation - 1707460162615.json";
import "../ErrorPage/ErrorPage.css";
import Lottie from "react-lottie";
import { Link } from "react-router-dom";
const ErrorPage = () => {
  return (
    <>
      <div className="container-fluid error-container">
        <div className="row error-row">
          <div className="col-md-12 error-col">
            <div className="error-imagediv">
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: errorani,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
              />
            </div>
            <div className="error-head">
              <h4>Ooops! It's Look like you can't move forward...</h4>
            </div>
            <div className="error-button">
              <Link className="back-home" to="/">
                Back to home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
