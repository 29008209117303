import React from "react";
import "./AeroSpace.css";
import { Link } from "react-router-dom";
import ProductData from "../../../Products.json";
import ReactPlayer from "react-player";
import HomeVideo from "../../../Assets/Images/Video/AeroSpaceVideo.mp4";

const AeroSpace = () => {
  const AccessrSection = ProductData.sections.find(
    (section) => section.id == 1
  );
  const AeroSpaceProduct = AccessrSection.products;

  return (
    <div>
      <div className="video-container">
        <ReactPlayer
          url={HomeVideo}
          width="100%"
          height="100%"
          controls={false}
          playing
          loop
          muted
        />
      </div>

      <section className="Aerospace-section-2">
        <div className="container">
          <div className="row" style={{ rowGap: "21px" }}>
            <div className="container">
              <div className="aerospace-product-head my-4">
                <h3>Aerospace</h3>
              </div>
            </div>

            <div className="row" style={{ rowGap: "30px" }}>
              {AeroSpaceProduct.map((item) => (
                <div key={item.id} className="col-lg-4 col-md-6">
                  <div className="inner-box mb-xl-30">
                    <div className="animation-img">
                      <img
                        src={item.image}
                        alt={item.title}
                        className="img-fit"
                      />
                    </div>
                    <div className="service-caption">
                      <div className="left-side">
                        <h4 className="fw-600 no-margin">{item.name}</h4>
                      </div>
                      <Link
                        to={`/inner-aerospace-industries/${item.id}`}
                        className="arr-button"
                      >
                        <i className="fa-solid fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AeroSpace;
