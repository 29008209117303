import React from "react";
import "./Blog.css";
import blog1 from "../../Assets/Images/blog/Cultural.png";
import blog2 from "../../Assets/Images/blog/Comprehensive.webp";
import blog3 from "../../Assets/Images/blog/Overcoming.webp";
import blog4 from "../../Assets/Images/blog/four.png";
import blog5 from "../../Assets/Images/blog/five.png";
import blog6 from "../../Assets/Images/blog/Machining1.png";
import blog7 from "../../Assets/Images/blog/aerospaceOutside.webp";
import blog8 from "../../Assets/Images/blog/outside.png";
import blog9 from "../../Assets/Images/blog/outsideBanner.webp";
import elevate from "../../Assets/Images/blog/elevateblog7.png";
import mastering from "../../Assets/Images/blog/masteringoutside.png";
import fromvision from "../../Assets/Images/blog/fromvisiontorealityouter.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Blog = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Senwell Exports || Blog</title>
        <link rel="canonical" href="https://senwellexports.com/blog" />
      </Helmet>
      <div className="blog-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="sub-header">
                <h1>Blog</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="blog-section-2 my-4">
        <div className="container">
          <div className="row">
            <div className="col-md-4 my-3">
              <Link
                className="blogLinks"
                to="/blog/The-Role-of-Precision-Manufacturing-in-Delivering-High-Quality-Products"
              >
                <div className="Exportcard blogbox">
                  <div className="px-4 pt-4">
                    <img src={blog9} alt="image" className="blog-ig" />
                  </div>
                  <div className="card-body mnhgt-175 px-4">
                    <p className="card-text">10 August, 2024</p>
                    <h4 className="card-title text-dark">
                      The Role of Precision Manufacturing in Delivering
                      High-Quality Products...
                    </h4>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-4 my-3">
              <Link
                className="blogLinks"
                to="/blog/Senwell-Exports-Reaches-New-Heights-with-AS9100D-and-ISO-9001:2015-Certification"
              >
                <div className="Exportcard blogbox">
                  <div className="px-4 pt-4">
                    <img src={blog8} alt="image" className="blog-ig" />
                  </div>
                  <div className="card-body mnhgt-175 px-4">
                    <p className="card-text">11 July, 2024</p>
                    <h4 className="card-title text-dark">
                      Senwell Exports Reaches New Heights with AS9100D and ISO
                      9001:2015 Certification
                    </h4>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-md-4 my-3">
              <Link
                className="blogLinks"
                to="/blog/Key-Factors-to-Consider-When-Choosing-an-Aerospace-Manufacturing-Company"
              >
                <div className="Exportcard blogbox">
                  <div className="px-4 pt-4">
                    <img src={blog7} alt="image" className="blog-ig" />
                  </div>
                  <div className="card-body mnhgt-175 px-4">
                    <p className="card-text">28 June , 2024</p>
                    <h4 className="card-title text-dark">
                      Key Factors to Consider When Choosing an Aerospace
                      Manufacturing Company...
                    </h4>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-4 my-3">
              <Link className="blogLinks" to="/blog/From-vision-to-reality">
                <div className="Exportcard blogbox">
                  <div className="px-4 pt-4">
                    <img src={fromvision} alt="image" className="blog-ig" />
                  </div>
                  <div className="card-body mnhgt-175 px-4">
                    <p className="card-text">14 May, 2024</p>
                    <h4 className="card-title text-dark">
                      From Vision to Reality: The Role of New Technologies in
                      Senwell Exports' Aerospace Innovation.
                    </h4>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-4 my-3">
              <Link className="blogLinks" to="/blog/mastering-precision">
                <div className="Exportcard blogbox">
                  <div className="px-4 pt-4">
                    <img src={mastering} alt="image" className="blog-ig" />
                  </div>
                  <div className="card-body mnhgt-175 px-4">
                    <p className="card-text">19 April, 2024</p>
                    <h4 className="card-title text-dark">
                      Mastering Precision: 6 Essential Tips for Selecting
                      Reliable Precision Component Suppliers
                    </h4>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-4 my-3">
              <Link className="blogLinks" to="/blog/elevateprecision">
                <div className="Exportcard blogbox">
                  <div className="px-4 pt-4">
                    <img src={elevate} alt="image" className="blog-ig" />
                  </div>
                  <div className="card-body mnhgt-175 px-4">
                    <p className="card-text">11 March, 2024</p>
                    <h4 className="card-title text-dark">
                      Elevate Precision Component Manufacturing with Senwell
                      Exports: Unveiling Our Superior Capabilities
                    </h4>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-md-4 my-3">
              <Link className="blogLinks" to="/blog/precision-machining">
                <div className="Exportcard blogbox">
                  <div className="px-4 pt-4">
                    <img src={blog6} alt="image" className="blog-ig" />
                  </div>
                  <div className="card-body mnhgt-175 px-4">
                    <p className="card-text">14 Feb, 2024</p>
                    <h4 className="card-title text-dark">
                      Precision Machining Benefits in Aerospace Industry
                    </h4>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-md-4 my-3">
              <Link className="blogLinks" to="/blog/Market">
                <div className="Exportcard blogbox">
                  <div className="px-4 pt-4">
                    <img src={blog5} alt="image" className="blog-ig" />
                  </div>
                  <div className="card-body mnhgt-175 px-4">
                    <p className="card-text">12 January, 2024</p>
                    <h4 className="card-title text-dark">
                      Market Strategies For Global Success for Exports’ Industry
                    </h4>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-md-4 my-3">
              <Link className="blogLinks" to="/blog/UnlockingSuccess">
                <div className="Exportcard blogbox">
                  <div className="px-4 pt-4">
                    <img src={blog4} alt="image" className="blog-ig" />
                  </div>
                  <div className="card-body mnhgt-175 px-4">
                    <p className="card-text">6 January, 2023</p>
                    <h4 className="card-title text-dark">
                      Importance of Supply Chain Management in Manufacturing .
                    </h4>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-md-4 my-3">
              <Link className="blogLinks" to="/blog/OvercomingManufacturing">
                <div className="Exportcard blogbox">
                  <div className="px-4 pt-4">
                    <img src={blog3} alt="image" className="blog-ig" />
                  </div>
                  <div className="card-body mnhgt-175 px-4">
                    <p className="card-text">12 July, 2023</p>
                    <h4 className="card-title text-dark">
                      Overcoming Manufacturing and Export Challenges
                    </h4>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-md-4 my-3">
              <Link className="blogLinks" to="/blog/CompresiveGuide">
                <div className="Exportcard blogbox">
                  <div className="px-4 pt-4">
                    <img src={blog2} alt="image" className="blog-ig" />
                  </div>
                  <div className="card-body mnhgt-175 px-4">
                    <p className="card-text">20 October, 2023</p>
                    <h4 className="card-title text-dark">
                      A Comprehensive guide to International Trade Regulations
                    </h4>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-md-4 my-3">
              <Link className="blogLinks" to="/blog/cultural-sensitivity">
                <div className="Exportcard blogbox">
                  <div className="px-4 pt-4">
                    <img src={blog1} alt="image" className="blog-ig" />
                  </div>
                  <div className="card-body mnhgt-175 px-4">
                    <p className="card-text">15 Dec, 2023</p>
                    <h4 className="card-title text-dark">
                      Cultural Sensitivity in International Trade
                    </h4>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Blog;
