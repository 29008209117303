import React, { useState, useEffect } from "react";
import "./SubInnerAccess.css";
import { useParams } from "react-router-dom";
import ProductData from "../../../../../Products.json";
import OrderForm from "../../../OrderForm/OrderForm";

const SubInnerAccess = () => {
  const [loading, setLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(true);
  const handleApplyNowClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setShowPopup((prev) => !prev);
  };
  const { categoryId, typeId } = useParams();

  const InnerAccessSection = ProductData.sections.find(
    (section) => section.id == 2
  );

  // State to store the fetched data
  const [innerAccessData, setInnerAccessData] = useState(null);

  useEffect(() => {
    const relevantData =
      InnerAccessSection.products[2] || [].find((item) => item.id === 203);

    let insideAccess = relevantData.insideProduct.find(
      (product) => product.type_id == categoryId
    );

    setInnerAccessData(insideAccess);
  }, [InnerAccessSection.products, typeId]);

  const product = innerAccessData?.InsideTinnedProduct.filter(
    (item) => item.type_id == typeId
  );

  if (!innerAccessData) {
    // Handle loading state or show an error message
    return <p>Loading...</p>;
  }

  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <div>
      <section className="subInner-section">
        <div className="container">
          <div className="row">
            {product.map((item, index) => (
              <>
                <div key={index} className="col-lg-4 col-md-6">
                  <div className="service-block-6 mb-xl-30">
                  {loading && <div className="loader"></div>}
                    <img
                      src={item.image}
                      alt={item.type_name}
                      className="sub-inner-img"
                      onLoad={handleImageLoad}
                    />
                  </div>
                </div>
                <div className="col-lg-8 col-md-6 sub-innner-right">
                  <div className="service-block-6 mb-xl-30">
                    <h2>{item.type_name}</h2>
                    <p>{item.dec}</p>
                    {item.CopperSpecification && (
                      <p>
                        <b>Copper-specification: </b>
                        {item.CopperSpecification}
                      </p>
                    )}
                    {item.CopperFinish && (
                      <p>
                        <b>Copper-finish: </b>
                        {item.CopperFinish}
                      </p>
                    )}
                    {item.Material && (
                      <p>
                        <b>Material: </b>
                        {item.Material}
                      </p>
                    )}
                    {item.Finish && (
                      <p>
                        <b>Finish: </b>
                        {item.Finish}
                      </p>
                    )}

                    {item.DrivingHead && (
                      <p>
                        <b>Driving Head: </b>
                        {item.DrivingHead}
                      </p>
                    )}
                    {item.DrivingSpike && (
                      <p>
                        <b>Driving Spike: </b>
                        {item.DrivingSpike}
                      </p>
                    )}
                    {item.InternalCoupling && (
                      <p>
                        <b>Internal Coupling: </b>
                        {item.InternalCoupling}
                      </p>
                    )}
                    {item.Features && (
                      <p>
                        <b>Features: </b>
                        {item.Features}
                      </p>
                    )}
                    {item.Note && (
                      <p>
                        <b>Note: </b>
                        {item.Note}
                      </p>
                    )}
                    {showPopup ? (
                      <div className="service-block mb-xl-30 position-relative">
                        <div className="text-start service-caption">
                          <button
                            className="btn btn btn-primary"
                            onClick={handleApplyNowClick}
                          >
                            Request a quotation
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="order-form-pop ">
                        <div
                          className="order-form-overlay "
                          onClick={handleApplyNowClick}
                        ></div>
                        <OrderForm />
                      </div>
                    )}
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default SubInnerAccess;
