import React, { useState } from "react";
import { Link } from "react-router-dom";
import ProductData from "../../../../Products.json";
import "./BrassElectrical.css";

const BrassElectrical = () => {
  const [loading, setLoading] = useState(true);
  const AccessrSection = ProductData.sections.find(
    (section) => section.id == 2
  );

  const relevantData =
    AccessrSection.products[3] || [].find((item) => item.id === 204);
  const InsideRelevent = relevantData.BrassProduct;

  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <div>
      <section className="brass-ElectricalA-Section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="brassE-subheader-content">
                <h1>Brass Electrical Accessories</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <h3 className="my-5">{relevantData.name}</h3>
          <div className="row" style={{ rowGap: "21px" }}>
            {InsideRelevent &&
              InsideRelevent.map((product) => (
                <div key={product.type_id} className="col-lg-4 col-md-6">
                  <div className="inner-box mb-xl-30">
                    <div className="animation-img">
                      <img
                        src={product.image}
                        alt={product.type_name}
                        className="img-fit"
                        onLoad={handleImageLoad}
                      />
                    </div>
                    {loading && <div className="loader"></div>}
                    <div className="service-caption">
                      <div className="left-side">
                        <h4 className="fw-600 no-margin">
                          {product.type_name}
                        </h4>
                      </div>
                      <Link
                        to={`/inner-brass-electrical-accessories/${product.type_id}`}
                        className="arr-button"
                      >
                        <i className="fa-solid fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default BrassElectrical;
