import React, { useState } from "react";
import "./SubInnerCasting.css";
import { useParams } from "react-router-dom";
import ProductData from "../../../../../Products.json";
import OrderForm from "../../../OrderForm/OrderForm";

const SubInnerCasting = () => {
  const [loading, setLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(true);
  const handleApplyNowClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setShowPopup((prev) => !prev);
  };

  const { categoryId, typeId } = useParams();

  const AccessrSection = ProductData.sections.find(
    (section) => section.id == 2
  );

  // Extract the relevant data from the found section
  const relevantData =
    AccessrSection.products[7] ||
    [].find((item) => item.id === 208).EarthingProduct;
  const CastingProducts = relevantData.CastingProduct;

  const Products = CastingProducts.filter(
    (product) => product.id == categoryId
  );
  const filteredProduct = Products[0].innerProduct;

  const castingProduct = filteredProduct.filter(
    (item) => item.type_id == typeId
  );
  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <div>
      <section className="subInner-section">
        <div className="container">
          <div className="row" style={{ rowGap: "30px" }}>
            {castingProduct.map((item, index) => (
              <>
                <div key={index} className="col-lg-4 col-md-6">
                  {loading && <div className="loader"></div>}
                  <div className="service-block-6 mb-xl-30">
                    <img
                      src={item.image}
                      alt={item.type_name}
                      className="sub-inner-img"
                      onLoad={handleImageLoad}
                    />
                  </div>
                </div>
                <div className="col-lg-8 col-md-6">
                  <div className="service-block-6 mb-xl-30">
                    <h2>{item.type_name}</h2>
                    <p>{item.dec}</p>
                    {item.Material && (
                      <p>
                        <b>Material: </b>
                        {item.Material}
                      </p>
                    )}

                    {item.Weights && (
                      <p>
                        <b>Weights: </b>
                        {item.Weights}
                      </p>
                    )}

                    {item.Size && (
                      <p>
                        <b>Size: </b>
                        {item.Size}
                      </p>
                    )}

                    {item.FinishAndCoating && (
                      <p>
                        <b>Finish And Coating: </b>
                        {item.FinishAndCoating}
                      </p>
                    )}

                    {item.Shape && (
                      <p>
                        <b>Shape: </b>
                        {item.Shape}
                      </p>
                    )}

                    {item.Finish && (
                      <p>
                        <b>Finish: </b>
                        {item.Finish}
                      </p>
                    )}

                    {item.last && <p>{item.last}</p>}

                    {showPopup ? (
                      <div className="service-block mb-xl-30 position-relative">
                        <div className="text-start service-caption">
                          <button
                            className="btn btn btn-primary"
                            onClick={handleApplyNowClick}
                          >
                            Request a quotation
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="order-form-pop ">
                        <div
                          className="order-form-overlay "
                          onClick={handleApplyNowClick}
                        ></div>
                        <OrderForm />
                      </div>
                    )}
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default SubInnerCasting;
