import React, { useState } from "react";
import { useParams } from "react-router-dom";
import ProductData from "../../../../../Products.json";
import OrderForm from "../../../OrderForm/OrderForm";

const SubInnerBrass = () => {
  const [loading, setLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(true);
  const handleApplyNowClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setShowPopup((prev) => !prev);
  };
  const { categoryId, typeId } = useParams();

  const AccessrSection = ProductData.sections.find(
    (section) => section.id == 2
  );
  const relevantData =
    AccessrSection.products[3] || [].find((item) => item.id === 204);
  const filteredProduct = relevantData.BrassProduct.filter(
    (product) => product.type_id == categoryId
  );

  const BrassCopperPro = filteredProduct[0].BrassCopperProduct.filter(
    (product) => product.type_id == typeId
  );
  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <>
      <section className="subInner-section">
        <div className="container">
          <div className="row">
            {BrassCopperPro.map((item, index) => {
              return (
                <>
                  <div key={index} className="col-lg-4 col-md-6">
                    {loading && <div className="loader"></div>}
                    <div className="service-block-6 mb-xl-30">
                      <img
                        src={item.image}
                        alt="img"
                        className="sub-inner-img"
                        onLoad={handleImageLoad}
                      />
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-6">
                    <div className="service-block-6 mb-xl-30">
                      <h2>{item.type_name}</h2>
                      <p>{item.dec}</p>
                      {item.Material && (
                        <p>
                          <b>Material: </b>
                          {item.Material}
                        </p>
                      )}

                      {item.Threads && (
                        <p>
                          <b>Threads: </b>
                          {item.Threads}
                        </p>
                      )}
                      {item.Finish && (
                        <p>
                          <b>Finish: </b>
                          {item.Finish}
                        </p>
                      )}
                      {item.Feature && (
                        <p>
                          <b>Features: </b>
                          {item.Feature}
                        </p>
                      )}
                      {item.Sizes && (
                        <p>
                          <b>Size: </b>
                          {item.Sizes}
                        </p>
                      )}

                      {item.Available && (
                        <p>
                          <b>Currently Available Terminal Blocks: </b>
                          {item.Available}
                        </p>
                      )}
                      {showPopup ? (
                        <div className="service-block mb-xl-30 position-relative">
                          <div className="text-start service-caption">
                            <button
                              className="btn btn btn-primary"
                              onClick={handleApplyNowClick}
                            >
                              Request a quotation
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div className="order-form-pop ">
                          <div
                            className="order-form-overlay "
                            onClick={handleApplyNowClick}
                          ></div>
                          <OrderForm />
                        </div>
                      )}
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default SubInnerBrass;
