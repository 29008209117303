import React, { useState } from "react";
import "./ContactUs.css";
import contactImg from "../../Assets/Images/contact.png";
import contactImg1 from "../../Assets/Images/contact1.png";
import db from "../../firebaseConfig";
import { collection, addDoc } from "firebase/firestore/lite";
import Swal from "sweetalert2";
import Translation from "../../i18n";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

const ContactUs = () => {
  const { t } = Translation();
  // State for form fields and errors
  const [formData, setFormData] = useState({
    company: "",
    name: "",
    email: "",
    phone: "",
    country: "",
    subject: "",
    message: "",
  });
  const navigate = useNavigate();
  const initialErrors = {
    company: false,
    name: false,
    email: false,
    phone: false,
    country: false,
    subject: false,
    message: false,
  };

  const [errors, setErrors] = useState(initialErrors);

  const [openModal, setOpenModal] = useState(false);

  // Handle form field changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Validate Company field on onBlur
  const handleCompanyBlur = () => {
    if (formData.company.trim() === "") {
      setErrors({ ...errors, company: "Please Enter Company Name" });
    } else {
      setErrors({ ...errors, company: false });
    }
  };

  // Validate Name field on onBlur
  const handleNameBlur = () => {
    if (formData.name.trim() === "") {
      setErrors({ ...errors, name: "Please Enter Name" });
    } else {
      setErrors({ ...errors, name: false });
    }
  };

  // Validate Email field on onBlur
  const handleEmailBlur = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      setErrors({ ...errors, email: "Invalid email address" });
    } else {
      setErrors({ ...errors, email: false });
    }
  };

  // Validate Phone field on onBlur
  const handlePhoneBlur = () => {
    const mobileRegex = /^[0-9]{10}$/;
    if (!mobileRegex.test(formData.phone)) {
      setErrors({ ...errors, phone: "Invalid phone number (10 digits)" });
    } else {
      setErrors({ ...errors, phone: false });
    }
  };

  // Validate Country field on onBlur
  const handleCountryBlur = () => {
    if (formData.country.trim() === "") {
      setErrors({ ...errors, country: "Please Country Name" });
    } else {
      setErrors({ ...errors, country: false });
    }
  };

  // Validate Subject field on onBlur
  const handleSubjectBlur = () => {
    if (formData.subject.trim() === "") {
      setErrors({ ...errors, subject: "Subject is required" });
    } else if (formData.subject.length > 50) {
      setErrors({
        ...errors,
        subject: "Subject should not exceed 50 characters",
      });
    } else {
      setErrors({ ...errors, subject: false });
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform form validation here
    let isValid = true;
    // Validate Company
    if (formData.company.trim() === "") {
      setErrors({ ...errors, company: "Company is required" });
      isValid = false;
    } else {
      setErrors({ ...errors, company: false });
    }

    // Validate Name
    if (formData.name.trim() === "") {
      setErrors({ ...errors, name: "Name is required" });
      isValid = false;
    } else {
      setErrors({ ...errors, name: false });
    }

    // Validate Email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      setErrors({ ...errors, email: "Invalid email address" });
      isValid = false;
    } else {
      setErrors({ ...errors, email: false });
    }

    // Validate Mobile
    const mobileRegex = /^[0-9]{10}$/;
    if (!mobileRegex.test(formData.phone)) {
      setErrors({ ...errors, phone: "Invalid phone number (10 digits)" });
      isValid = false;
    } else {
      setErrors({ ...errors, phone: false });
    }

    // Validate Country
    if (formData.country.trim() === "") {
      setErrors({ ...errors, country: "Country is required" });
      isValid = false;
    } else {
      setErrors({ ...errors, country: false });
    }

    // Validate Subject
    if (formData.subject.trim() === "") {
      setErrors({ ...errors, subject: "Subject is required" });
      isValid = false;
    } else if (formData.subject.length > 50) {
      setErrors({
        ...errors,
        subject: "Subject should not exceed 50 characters",
      });
      isValid = false;
    } else {
      setErrors({ ...errors, subject: false });
    }

    if (
      !formData.company.trim() ||
      !formData.name.trim() ||
      !formData.email.trim() ||
      !formData.phone.trim() ||
      !formData.country.trim() ||
      !formData.subject.trim()
    ) {
      setErrors({
        company: !formData.company.trim()
          ? "Please enter your company name."
          : false,
        name: !formData.name.trim() ? "Please enter your name." : false,
        email: !formData.email.trim()
          ? "Please enter your email address.."
          : false,
        phone: !formData.phone.trim()
          ? "Please enter your Phone Number."
          : false,
        country: !formData.country.trim() ? "Please enter your country" : false,
        subject: !formData.subject.trim() ? "Subject is required" : false,
      });
      isValid = false;
    }

    // push the code in firebase
    if (isValid) {
      try {
        const contactsRef = collection(db, "ExportNewContacts");
        await addDoc(contactsRef, formData);
        setFormData({
          company: "",
          name: "",
          email: "",
          phone: "",
          country: "",
          subject: "",
          message: "",
        });
        navigate("/thankyou");
        // setOpenModal(true);
        // Swal.fire({
        //   title: "Success!",
        //   text: "Data sent successfully!",
        //   icon: "success",
        //   showClass: {
        //     popup: "animate__animated animate__fadeInDown",
        //   },
        //   hideClass: {
        //     popup: "animate__animated animate__fadeOutUp",
        //   },
        // });
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Senwell Exports || ContactUs</title>
        <link rel="canonical" href="https://senwellexports.com/contact" />
      </Helmet>
      <section className="service-section">
        <div className="service-bg-img">
          <img src={contactImg} className="img-fluid" alt="Background"></img>
          <div className="text-overlay">
            <h1>{t("contact-head")}</h1>
          </div>
        </div>
      </section>

      <section className="contact-form-section pt-5  p-3 pb-5 bg-body-tertiary rounded">
        <div className="container">
          <div className="row" style={{ rowGap: "20px" }}>
            <div className="col-md-4 contact-img-section">
              <img src={contactImg1} className="img-thumbnail" alt="img" />
            </div>
            <div className="col-lg-8">
              <div className="wrapper-box">
                <div className="contact-form">
                  <div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            name="company"
                            value={formData.company}
                            placeholder="Company Name"
                            onChange={handleInputChange}
                            onBlur={handleCompanyBlur}
                          />
                        </div>
                        {errors.company && (
                          <div className="invalid-feed">
                            <p>{errors.company}</p>
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            name="name"
                            value={formData.name}
                            placeholder="Your Name"
                            onChange={handleInputChange}
                            onBlur={handleNameBlur}
                          />
                        </div>
                        {errors.name && (
                          <div className="invalid-feed">
                            <p>{errors.name}</p>
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            name="email"
                            value={formData.email}
                            placeholder="Your Email"
                            onChange={handleInputChange}
                            onBlur={handleEmailBlur}
                          />
                        </div>
                        {errors.name && (
                          <div className="invalid-feed">{errors.email}</div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="tel"
                            name="phone"
                            value={formData.phone}
                            placeholder="Enter Your Phone"
                            onChange={(e) => {
                              const numericValue = e.target.value.replace(
                                /\D/g,
                                ""
                              );
                              setFormData({
                                ...formData,
                                phone: numericValue,
                              });
                            }}
                            onBlur={handlePhoneBlur}
                            maxLength={10}
                            onInput={(e) => {
                              if (e.target.value.length > 10) {
                                e.target.value = e.target.value.slice(0, 10);
                              }
                            }}
                          />
                        </div>
                        {errors.phone && (
                          <div className="invalid-feed">
                            <p>{errors.phone}</p>
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            name="country"
                            value={formData.country}
                            placeholder="Your Country"
                            onChange={handleInputChange}
                            onBlur={handleCountryBlur}
                          />
                        </div>
                        {errors.country && (
                          <div className="invalid-feed">
                            <p>{errors.country}</p>
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            name="subject"
                            value={formData.subject}
                            placeholder="Your Subject"
                            onChange={handleInputChange}
                            onBlur={handleSubjectBlur}
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <textarea
                            name="message"
                            value={formData.message}
                            placeholder="Enter Your Message"
                            onChange={handleInputChange}
                          ></textarea>
                        </div>
                      </div>

                      <div className="send-btn">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleSubmit}
                        >
                          {t("Send-Now")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="factory-location mt-3">
        <div className="location">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3782.0057482627994!2d74.01094767496421!3d18.573779582530175!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c3795bc1ac51%3A0xf46212a43682896!2sSenwell%20Exports%20Private%20Limited!5e0!3m2!1sen!2sin!4v1705906285120!5m2!1sen!2sin"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </section>

      <br />
      <br />
      <br />
      <br />

      <section className="contact-last-section mt-5 mb-5">
        <div className="container">
          <div className="row ">
            <div className="col-md-3  contact-column">
              <div className="card text-center">
                <div className="card-icon-section">
                  <div className="icon  ">
                    <i className="fa-solid fa-location-dot fa-beat fa-2x "></i>
                  </div>
                </div>
                <div className="card-body">
                  <h5 className="card-title">{t("Location")}</h5>
                  <p className="card-text text-center">
                    {t("Factory")} <br />
                    {t("High")} <br />
                    {t("Road")} <br />
                    Pune- 412207
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3 contact-column ">
              <div className="card text-center">
                <div className="card-icon-section">
                  <div className="icon">
                    <i className="fa-solid fa-globe fa-beat fa-2xl"></i>
                  </div>
                </div>
                <div className="card-body">
                  <h5 className="card-title">{t("Web-Connect")}</h5>
                  <p className="card-text text-center mt-4">
                    <a
                      href="https://senwellexports.com/"
                      target="_blank"
                      className="text-decoration-none "
                    >
                      www.senwellexports.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3 contact-column ">
              <div className="card text-center">
                <div className="card-icon-section">
                  <div className="icon">
                    <i className="fa-solid fa-envelope fa-beat fa-2xl"></i>
                  </div>
                </div>
                <div className="card-body">
                  <h5 className="card-title">{t("contact-head")}</h5>
                  <p className="card-text text-center  fw-medium">
                    {t("Email")}
                  </p>
                  <a
                    href="mailto:info@senwellexports.com"
                    className="text-decoration-none"
                  >
                    info@senwellexports.com
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-3  contact-column">
              <div className="card text-center">
                <div className="card-icon-section">
                  <div className="icon">
                    <i className="fa-solid fa-phone fa-beat fa-2xl"></i>
                  </div>
                </div>
                <div className="card-body">
                  <h5 className="card-title">Talk to Us</h5>
                  <p className="card-text text-center fw-medium">Phone</p>
                  <a
                    href="tel:+91 9175447940 "
                    className="text-decoration-none"
                  >
                    +91 9175447940
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
