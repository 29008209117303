import React from "react";
import "./Infrastructure.css";
import Infra from "../../Assets/Images/Infra/InfrastructureBanner.png";
import Machine1 from "../../Assets/Images/Infra/NewM-0.png";
import Machine2 from "../../Assets/Images/Infra/NewM-1.png";
import Machine3 from "../../Assets/Images/Infra/NewM-2.png";
import VmcAxis3 from "../../Assets/Images/Infra/Vmc3axis.png";
import VmcAxis4 from "../../Assets/Images/Infra/Vmc4axis.png";
import Machine5 from "../../Assets/Images/Infra/NewM-4.png";
import Machine6 from "../../Assets/Images/Infra/NewM-6.png";
import Machine7 from "../../Assets/Images/Infra/NewM-5.png";
import Machine8 from "../../Assets/Images/Infra/NewM-7.png";
import Machine9 from "../../Assets/Images/Infra/NewM-8.png";
import Machine10 from "../../Assets/Images/Infra/dhgmachine.png";
import Machine15 from "../../Assets/Images/Infra/machine15.png";
import Machine16 from "../../Assets/Images/Infra/machine16.png";
import Machine17 from "../../Assets/Images/Infra/machine17.png";
import Machine18 from "../../Assets/Images/Infra/machine18.png";
import Machine14 from "../../Assets/Images/Infra/machine14.webp";
import Translation from "../../i18n";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";

const Infrastructure = () => {
  const navigate = useNavigate();

  const { t } = Translation();
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Senwell Exports || Infrastructure</title>
        <link
          rel="canonical"
          href="https://senwellexports.com/infrastructure"
        />
      </Helmet>
      <div>
        <div className="Infrastructure-section-home">
          <div className="infrastructure-img">
            <img src={Infra} alt="img" />
          </div>
          <div className="text-Infra">
            <h1>{t("Infrastructure")}</h1>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <article className="content span12">
              <div className="row">
                <div className="span12 sepage-header">
                  <h1>{t("Machining-Set")}</h1>
                  <p align="justify">{t("Machining Set Text")}</p>
                </div>
                <p>&nbsp;</p>
              </div>
            </article>
          </div>
        </div>

        <section className="Unit-Sections">
          <div className="container px-4">
            <h1>{t("Innovative")}</h1>
            <p>{t("Innovative-text")}</p>
            <div className="row gx-5" style={{ rowGap: "60px" }}>
              <div className="col-lg-4">
                <img src={Machine1} alt="img" className="machine-image" />
              </div>
              <div className="col-lg-4">
                <img
                  src={VmcAxis3}
                  alt="img"
                  className="machine-image"
                  onClick={() => navigate("/vmc3axismachine")}
                  style={{ cursor: "pointer" }}
                />
                <h5 style={{ textAlign: "center" }}>
                  Vertical Machinig Center 3-Axis
                </h5>
              </div>
              <div className="col-lg-4">
                <img
                  src={VmcAxis4}
                  alt="img"
                  className="machine-image"
                  onClick={() => navigate("/vmc4axismachine")}
                  style={{ cursor: "pointer" }}
                />
                <h5 style={{ textAlign: "center" }}>
                  Vertical Machinig Center 4-Axis
                </h5>
              </div>
              <div className="col-lg-4">
                <img
                  src={Machine16}
                  alt="img"
                  className="machine-image"
                  style={{ cursor: "pointer" }}
                />
                <h5 style={{ textAlign: "center" }}>CNC Turning (LL20TM L5)</h5>
              </div>{" "}
              <div className="col-lg-4">
                <img
                  src={Machine17}
                  alt="img"
                  className="machine-image"
                  style={{ cursor: "pointer" }}
                />
                <h5 style={{ textAlign: "center" }}>
                  CNC Turning (LL30TM L10)
                </h5>
              </div>{" "}
              <div className="col-lg-4">
                <img
                  src={Machine18}
                  alt="img"
                  className="machine-image"
                  style={{ cursor: "pointer" }}
                />
                <h5 style={{ textAlign: "center" }}>VMC 3Axis (J2)</h5>
              </div>{" "}
              <div className="col-lg-4">
                <img
                  src={Machine5}
                  alt="img"
                  className="machine-image"
                  onClick={() => navigate("/cnc02machine")}
                  style={{ cursor: "pointer" }}
                />
                <h5 style={{ textAlign: "center" }}>CNC Turning Center 02</h5>
              </div>
              <div className="col-lg-4">
                <img
                  src={Machine6}
                  alt="img"
                  className="machine-image cursor-pointer"
                  onClick={() => navigate("/cmmmachine")}
                  style={{ cursor: "pointer" }}
                />
                <h5 style={{ textAlign: "center" }}>
                  Coordinate Measuring Machine
                </h5>
              </div>
              <div className="col-lg-4">
                <img
                  src={Machine8}
                  alt="img"
                  className="machine-image"
                  onClick={() => navigate("/vmmmachine")}
                  style={{ cursor: "pointer" }}
                />
                <h5 style={{ textAlign: "center" }}>
                  Vision Measuring Machine
                </h5>
              </div>
              <div className="col-lg-4 ">
                <img src={Machine9} alt="img" className="machine-image" />
                <h5 style={{ textAlign: "center" }}>Special Purpose Machine</h5>
              </div>
              <div className="col-lg-4">
                <img
                  src={Machine7}
                  alt="img"
                  className="machine-image"
                  onClick={() => navigate("/hmmmachine")}
                  style={{ cursor: "pointer" }}
                />
                <h5 style={{ textAlign: "center" }}>2D Heigth Gauge</h5>
              </div>
              <div className="col-lg-4">
                <img
                  src={Machine3}
                  alt="img"
                  className="machine-image"
                  onClick={() => navigate("/srtmachine")}
                  style={{ cursor: "pointer" }}
                />
                <h5 style={{ textAlign: "center" }}>
                  Surface Roughness Tester
                </h5>
              </div>
              <div className="col-lg-4">
                <img
                  src={Machine10}
                  alt="img"
                  className="machine-image"
                  onClick={() => navigate("/dhgmachine")}
                  style={{ cursor: "pointer" }}
                />
                <h5 style={{ textAlign: "center" }}>Digital Height Gauge</h5>
              </div>{" "}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Infrastructure;
