import React from "react";
import "./OilandGas.css";
import { Link } from "react-router-dom";
import ProductData from "../../../Products.json";
import HomeVideo from "../../../Assets/Images/Video/oil&Gas.mp4";
import ReactPlayer from "react-player";

const OilandGas = () => {
  const OilandGasSection = ProductData.sections.find(
    (section) => Number(section.id) === 3
  );
  if (!OilandGasSection) {
    return <div>Oil and Gas are not Found</div>;
  }

  // Extract the relevant data from the found section
  const typesData = OilandGasSection.types;

  return (
    <div>
      <div className="video-container">
        <ReactPlayer
          url={HomeVideo}
          width="100%"
          height="100%"
          controls={false}
          playing
          loop
          muted
        />
      </div>

      <section className="oil-gas-section">
        <div className="container">
          <div className="row" style={{ margin: "0", padding: "0" }}>
            <div className="container">
              <div className="oil-header my-4">
                <h3>{OilandGasSection.name}</h3>
              </div>
            </div>

            <ul className="oil-ul">
              <li>
                <strong>MULTI-AXIS MACHINING CENTERS:</strong>
                <span className="oilandgastext">{OilandGasSection.Multi}</span>
              </li>

              <li>
                <strong>SECONDARY PROCESSES:</strong>
                <spna className="oilandgastext">
                  {OilandGasSection.secondary}
                </spna>
              </li>

              <li>
                <strong>SPECIAL THREADS & PROFILES: </strong>
                <span className="oilandgastext">
                  {OilandGasSection.Special}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="ProductSection">
        <div className="container">
          <div className="row" style={{ rowGap: "21px" }}>
            {typesData.map((type) => (
              <div key={type.type_id} className="col-lg-4 col-md-6">
                <div className="inner-box mb-xl-30">
                  <div className="animation-img">
                    <img src={type.image} alt="img" className="img-fit" />
                  </div>

                  <div className="service-caption">
                    <div className="left-side">
                      <h4 className="fw-600 no-margin">{type.type_name}</h4>
                      <h4 className="fw-600 no-margin">{type.type_name1}</h4>
                    </div>
                    <Link
                      to={`/oil-gas/${type.type_id}`}
                      className="arr-button"
                    >
                      <i className="fa-solid fa-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default OilandGas;
