import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import CountLessBanenr from "../../Assets/Images/CountLessBanner.png";
import countless from "../../Assets/Images/Countless.png";
import Inconel from "../../Assets/Images/Inconel.png";
import Titanium from "../../Assets/Images/Titanium.png";
import whyImg from "../../Assets/Images/about-img.png";
import crouselImg1 from "../../Assets/Images/about1.png";
import crouselImg2 from "../../Assets/Images/about2.png";
import crouselImg3 from "../../Assets/Images/about3.png";
import Img from "../../Assets/Images/aboutHome.png";
import mat1 from "../../Assets/Images/aboutIcons/11.webp";
import mat2 from "../../Assets/Images/aboutIcons/12.webp";
import mat3 from "../../Assets/Images/aboutIcons/13.webp";
import mat4 from "../../Assets/Images/aboutIcons/14.webp";
import infra1 from "../../Assets/Images/aboutIcons/infra1.png";
import infra2 from "../../Assets/Images/aboutIcons/infra2.png";
import infra3 from "../../Assets/Images/aboutIcons/infra3.png";
import infra4 from "../../Assets/Images/aboutIcons/infra4.png";
import infra5 from "../../Assets/Images/aboutIcons/infra5New.png";
import infra6 from "../../Assets/Images/aboutIcons/infra6.png";
import mission from "../../Assets/Images/aboutIcons/mission.png";
import quality from "../../Assets/Images/aboutIcons/quality.png";
import team from "../../Assets/Images/aboutIcons/team.png";
import vision from "../../Assets/Images/aboutIcons/vision.png";
import Translation from "../../i18n";
import "./About.css";

const About = () => {
  const { t } = Translation();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Senwell Exports || AboutUs</title>
        <link rel="canonical" href="https://senwellexports.com/about" />
        <meta name="description" content={t("metaDescription")} />
        <meta property="og:title" content={t("ogTitle")} />
        <meta property="og:description" content={t("ogDescription")} />
        <meta property="og:image" content={Img} />
        <meta property="og:image:alt" content="AboutUs Image" />
        {/* Twitter metadata */}
        <meta
          name="twitter:card"
          content="Senwell Exports Private Limited. is a dynamic and vibrant enterprise specializing in the production and exportation of top-tier non-ferrous metal components and electrical accessories. "
        />
        <meta name="twitter:site" content="@YourTwitterHandle" />
        <meta name="twitter:title" content={t("AboutUsTitle")} />
        <meta name="twitter:description" content={t("metaDescription")} />
        <meta name="twitter:image" content={Img} />
        {/* LinkedIn metadata */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://senwellexports.com/about" />
        <meta property="og:senwellExport" content="Senwell Export" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Senwell Exports",
              "url": "https://senwellexports.com",
              "logo": "https://senwellexports.com/static/media/SE-logo.a737f6d9d088554f7975.png",
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91 9175447940",
                "contactType": "customer service",
                "contactOption": "TollFree",
                "areaServed": "US"
              }
            }
          `}
        </script>
      </Helmet>
      <section className="about-section-home">
        <div className="about-section-img">
          <img src={Img} className="img-fluid" alt="img" />
          <div className="text-overlay">
            <h2>{t("AboutUs")}</h2>
          </div>
        </div>
      </section>

      <section className="about-section-second">
        <div className="container shadow p-3 rounded">
          <div className="row d-flex justify-content-center">
            <div className="col-xl-4 col-md-4 col-sm-8 ">
              <img
                src={CountLessBanenr}
                className="img-fluid mt-5"
                alt="img"
                style={{ width: "100%", marginTop: "0px" }}
              />
            </div>
            <div className="col-xl-8 col-md-8 ">
              <div className="about-section-second-heading ">
                <h2> {t("Countless")} </h2>
                <h2 className="mx-2 purpose">{t("onePurpose")}</h2>
              </div>
              <div className="about-section-second-para mt-4">
                <p>{t("counttext")}</p>
                <p>{t("counttext1")}</p>
              </div>
              <div className="about-section-second-icons-section  mt-4">
                <div className="row">
                  <img src={countless} alt="img" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-section-third-opportunity mt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="heading">
                <h2>{t("big")}</h2>
                <h2 className="mx-2 opportunity">{t("business")}</h2>
              </div>
              <div className="heading-para ">
                <p>{t("growthtext")}</p>
                <p>{t("growthtext1")}</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="lightbox">
                <div className="row lastimg" style={{ marginTop: "54px" }}>
                  <div className="col-lg-6">
                    <img
                      src={crouselImg1}
                      data-mdb-img="https://mdbcdn.b-cdn.net/img/Photos/Slides/1.webp"
                      alt="Table Full of Spices"
                      className="w-100 h-50 mb-2 mb-md-4 shadow-1-strong "
                    />
                    <img
                      src={crouselImg2}
                      data-mdb-img="https://mdbcdn.b-cdn.net/img/Photos/Square/1.webp"
                      alt="Coconut with Strawberries"
                      className="w-100 h-50  shadow-1-strong "
                    />
                  </div>
                  <div className="col-lg-6 last-img last-mg">
                    <img
                      src={crouselImg3}
                      data-mdb-img="https://mdbcdn.b-cdn.net/img/Photos/Vertical/1.webp"
                      alt="Dark Roast Iced Coffee"
                      className="w-100   shadow-1-strong "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-section-mission">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 ">
              <div className="about-section-mission-img">
                <img src={mission} alt="img" />
              </div>
              <div className="card">
                <div className="card-body ">
                  <a href="#" className="custom-button mb-2">
                    {t("mision")}
                  </a>
                  <p>{t("missiontext")}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mission-col1">
              <div className="about-section-mission-img">
                <img src={vision} className="img-thumbnail" alt="image"></img>
              </div>
              <div className="card">
                <div className="card-body ">
                  <a href="#" className="custom-button mb-2">
                    {t("vision")}
                  </a>
                  <p>{t("visiontext")}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mission-col">
              <div className="about-section-mission-img">
                <img src={team} className="img-thumbnail" alt="image"></img>
              </div>
              <div className="card">
                <div className="card-body">
                  <a href="#" className="custom-button mb-2">
                    {t("Management")}
                  </a>
                  <p>{t("Management-text")}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6  mission-col">
              <div className="about-section-mission-img">
                <img src={quality} className="img-thumbnail" alt="quality"></img>
              </div>
              <div className="card">
                <div className="card-body ">
                  <a href="#" className="custom-button mb-2">
                    {t("Quality")}
                  </a>
                  <p>{t("quality-text")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="whychoose-sections">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 why-leftSide">
              <h2>
                {t("why")} <span>{t("Choose")}</span>
              </h2>
              <p>{t("Choose-text")}</p>
              <div className="row section4-row2">
                <div className="col-lg-4  my-3 ">
                  <div className="card-section">
                    <div className="row card-row1">
                      <div className="col-lg-12 col-para">
                        <p>{t("Reasonable")}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 my-3 ">
                  <div className="card-section">
                    <div className="row card-row1">
                      <div className="col-lg-12 col-para">
                        <p>{t("customer")}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 my-3 ">
                  <div className="card-section">
                    <div className="row card-row1">
                      <div className="col-lg-12 col-para">
                        <p>{t("Experienced")}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 my-3 ">
                  <div className="card-section">
                    <div className="row card-row1">
                      <div className="col-lg-12 col-para">
                        <p>{t("Exceptional")}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 my-3 ">
                  <div className="card-section">
                    <div className="row card-row1">
                      <div className="col-lg-12 col-para">
                        <p>{t("Packaging")}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 my-3 ">
                  <div className="card-section">
                    <div className="row card-row1">
                      <div className="col-lg-12 col-para">
                        <p>{t("Approach")}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 my-3 ">
                  <div className="card-section">
                    <div className="row card-row1">
                      <div className="col-lg-12 col-para">
                        <p>{t("Superior")}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 my-3 ">
                  <div className="card-section">
                    <div className="row card-row1">
                      <div className="col-lg-12 col-para">
                        <p>{t("Timely")}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 my-3  ">
                  <div className="card-section">
                    <div className="row card-row1">
                      <div className="col-lg-12 col-para">
                        <p>{t("Speciality")} </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 section2-col-2 why-img">
              <img src={whyImg} alt="img" className="mt-5" />
            </div>
          </div>
        </div>
      </section>

      <section className="material-section">
        <div className="container">
          <div className="section-title">
            <h2>{t("materials")}</h2>

            <div className="row">
              <div className="team-block col-lg-4 col-md-6 col-sm-12 my-3 ">
                <div>
                  <div className="m-image">
                    <img src={Titanium} alt="img" />
                  </div>
                </div>
                <div className="lower-content-titanium">
                  <h4>{t("Titanium")}</h4>
                </div>
              </div>
              <div className="team-block col-lg-4 col-md-6 col-sm-12 my-3 ">
                <div>
                  <div className="m-image">
                    <img src={Inconel} alt="img" />
                  </div>
                </div>
                <div className="lower-content-Inconel">
                  <h4>{t("Inconel")}</h4>
                </div>
              </div>
              <div className="team-block col-lg-4 col-md-6 col-sm-12 my-3 ">
                <div>
                  <div className="m-image">
                    <img src={mat2} alt="img" />
                  </div>
                </div>
                <div className="lower-content-copper">
                  <h4>{t("Stainless-Steel")}</h4>
                </div>
              </div>
              <div className="team-block col-lg-4 col-md-6 col-sm-12 my-3 ">
                <div>
                  <div className="m-image">
                    <img src={mat3} alt="img" />
                  </div>
                </div>
                <div className="lower-content-copper">
                  <h4>{t("Copper")}</h4>
                </div>
              </div>

              <div className="team-block col-lg-4 col-md-6 col-sm-12 my-3 ">
                <div>
                  <div className="m-image">
                    <img src={mat1} alt="img" />
                  </div>
                </div>
                <div className="lower-content-brass">
                  <h4>{t("Brass")}</h4>
                </div>
              </div>

              <div className="team-block col-lg-4 col-md-6 col-sm-12 my-3 ">
                <div>
                  <div className="m-image">
                    <img src={mat4} alt="img" />
                  </div>
                </div>
                <div className="lower-content-Alu">
                  <h4>{t("Aluminium")}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="our-infrastructure-section mt-3">
        <div className="container infrastructure-section">
          <div className="row">
            <div className="col-md-6" style={{ marginTop: "50px" }}>
              <div className="infra-heading">
                <h1>{t("our")}</h1>
                <h1 className="infra">{t("Infrastructure")}</h1>
              </div>
              <div className="infra-para mt-3">
                <p>{t("infratext1")}</p>
                {/* <p>{t("infratext2")}</p> */}
              </div>

              <div className="infra-readMore">
                <Link to="/infrastructure" className="inner-infra">
                  {t("ReadMore")}
                </Link>
              </div>
            </div>

            <div className="col-md-6 ">
              <div className="row infra-img-section mt-4">
                <div className="col-md-4 p-1">
                  <img src={infra1} className="img-thumbnail h-100" alt="infra" />
                </div>
                <div className="col-md-4 p-1">
                  <img src={infra2} className="img-thumbnail h-100" alt="infra" />
                </div>
                <div className="col-md-4 p-1">
                  <img src={infra3} className="img-thumbnail w-100 h-100" alt="infra" />
                </div>
              </div>
              <div className="row infra-img-section">
                <div className="col-md-3 p-1">
                  <img src={infra4} className="img-thumbnail h-100" alt="infra" />
                </div>
                <div className="col-md-3 p-1">
                  <img src={infra5} className="img-thumbnail h-100" alt="infra" />
                </div>
                <div className="col-md-6 p-1">
                  <img src={infra6} className="img-thumbnail last-img" alt="infra" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />
    </>
  );
};

export default About;
