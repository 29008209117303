import React, { useState, useEffect } from "react";
import "./Slider.css";

import I1 from "../../Assets/Images/I1.png";
import I2 from "../../Assets/Images/I2.png";
import I3 from "../../Assets/Images/I3.png";
import I4 from "../../Assets/Images/I4.png";
import I5 from "../../Assets/Images/I5.png";
import I6 from "../../Assets/Images/I6.png";
import In6 from "../../Assets/Images/our-industries.png";
import Translation from "../../i18n";

function Slider() {
  const { t } = Translation();
  const [index, setIndex] = useState(0);

  const images = [I1, I2, I3, I4, I5, I6];

  useEffect(() => {
    const layout = () => {
      const xOffsetStep = 100;
      const count = images.length;
      const scaleStep = 0.6;
      const opacityStep = 0.5;

      const imgContainer = document.querySelector(".img-container");
      const imgs = imgContainer.querySelectorAll("img");

      imgs.forEach((img, i) => {
        const sign = Math.sign(i - index);

        let xOffset = (i - index) * xOffsetStep;
        if (i !== index) {
          xOffset = xOffset + 80 * sign;
        }
        const scale = scaleStep ** Math.abs(i - index);
        const rotateY = i === index ? 0 : 30 * -sign;
        img.style.transform = `perspective(800px) translateX(${xOffset}px) scale(${scale}) rotateY(${rotateY}deg)`;

        let opacity = opacityStep ** Math.abs(i - index);
        if (Math.abs(i - index) > 2) {
          opacity = 0;
        }
        img.style.opacity = opacity;

        img.style.zIndex = count - Math.abs(index - i);
      });
    };

    layout();
  }, [index]);

  const handleNext = () => {
    setIndex((prevIndex) =>
      prevIndex < images.length - 1 ? prevIndex + 1 : images.length - 1
    );
  };

  const handlePrev = () => {
    setIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
  };

  return (
    <>
      <section className="slider">
        <div className="container">
          <div className="row server-header">
            <h2 className="text-center mt-1">
              {t("Industries")} <span>{t("WeServe")}</span>
            </h2>
            <div className="col-md-12">
              <div className="image-slider-section">
                <div className="img-container">
                  {images.map((image, i) => (
                    <img key={i} src={image} alt={`img${i + 1}`} />
                  ))}
                  <div className="arrows">
                    <svg
                      className="prev"
                      viewBox="0 0 256 512"
                      width="100"
                      title="angle-left"
                      onClick={handlePrev}
                    >
                      <path d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z" />
                    </svg>
                    <svg
                      className="next"
                      viewBox="0 0 256 512"
                      width="100"
                      title="angle-right"
                      onClick={handleNext}
                    >
                      <path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="our-industries-img-section-none">
          <img src={In6} className="img-fluid" alt="img" />
        </div>
      </section>
    </>
  );
}

export default Slider;
