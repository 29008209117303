import { useTranslation } from "react-i18next";

const Translation = () => {
  const { t, i18n } = useTranslation();
  return {
    t,
    i18n,
  };
};

export default Translation;
