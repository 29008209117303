import React, { useState } from "react";
import "./InnerAeroSpace.css";
import { useParams } from "react-router-dom";
import ProductData from "../../../../Products.json";
import OrderForm from "../../OrderForm/OrderForm";

const InnerAeroSpace = () => {
  const [loading, setLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(true);

  const { typeId } = useParams();

  const AccessrSection = ProductData.sections.find(
    (section) => section.id == 1
  );
  const AeroSpaceProduct = AccessrSection.products;
  const product = AeroSpaceProduct.filter((item) => item.id == typeId);
  const handleImageLoad = () => {
    setLoading(false);
  };

  const handleApplyNowClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setShowPopup((prev) => !prev);
  };
  return (
    <>
      <div className="CNCTurned-Section">
        {product.map((item, index) => {
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="subheader-content">
                  <h1> {item.name}</h1>
                </div>
              </div>
            </div>
          </div>;
        })}
      </div>
      <div className="container">
        {product.map((item, index) => {
          return (
            <>
              <section className="cnc-section-2">
                <div className="container">
                  <div className="cncproduct my-4">
                    <h3 className="fw-600 mb-4">{item.name}</h3>
                    <p>{item.description}</p>
                  </div>

                  <div className="row img-row">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="cnc-main-img">
                        <img
                          className="InnerAerospaceImg"
                          src={item.images[0]}
                          alt="img"
                          onLoad={handleImageLoad}
                        />
                      </div>
                    </div>
                    {loading && <div className="loader"></div>}
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="cnc-main-img">
                        <img
                          className="InnerAerospaceImg"
                          src={item.images[1]}
                          alt="img"
                          onLoad={handleImageLoad}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="cnc-main-img">
                        <img
                          className="InnerAerospaceImg"
                          src={item.images[2]}
                          alt="img"
                          onLoad={handleImageLoad}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="cnc-section-3">
                <div className="container">
                  <div className="row">
                    <div className="cnc-block">
                      <h2>Description</h2>
                      <div className="cnc-text">
                        <p>{item.additionalText}</p>
                        <p>
                          Each of our products undergoes a rigorous quality
                          check to identify and eliminate any potential issues,
                          ensuring that they meet international standards. This
                          meticulous process is carried out to guarantee that
                          the products you receive are of the highest quality
                          and usability. Feel free to contact us to request a
                          quote!
                        </p>

                        {typeId == 101 && (
                          <section>
                            <h4>Salient Features of CNC Turned Parts:</h4>
                            <ul className="cnc-fe">
                              <li>
                                Exceptionally high-quality parts can be produced
                                with a turning diameter ranging from 10mm to
                                70mm.
                              </li>
                              <li>
                                High precision enables the attainment of
                                accuracy within the range of 10 to 15 microns.
                              </li>
                              <li>
                                {" "}
                                Delivers a superior surface finish of high
                                quality.
                              </li>
                              <li>
                                {" "}
                                Achieving zero-defect products with impeccable
                                accuracy, resulting in 0 PPM.{" "}
                              </li>
                              <li>
                                {" "}
                                Well-suited for precision components that
                                require highly customized specifications.
                              </li>
                              <li>
                                {" "}
                                Effectively manufacturing intricate components
                                is achievable.
                              </li>
                              <li>
                                {" "}
                                We can accommodate small batch quantities,
                                providing a benefit to customers with limited or
                                rapidly changing requirements.{" "}
                              </li>
                              <li>
                                {" "}
                                We can manufacture special turned parts
                                according to customer specifications of any
                                kind.{" "}
                              </li>
                            </ul>
                          </section>
                        )}
                      </div>

                      {typeId == 101 && (
                        <section>
                          <table className="table table-bordered">
                            <tbody>
                              <tr>
                                <td
                                  valign="top"
                                  style={{
                                    height: "21",
                                    backgroundColor: "#f0f0f0",
                                  }}
                                >
                                  <strong>Material :</strong>
                                </td>
                                <td>
                                  - Free Cutting Brass IS 319 Type (I) or BS 249
                                  Type (I) or High Grade <br />
                                  - Crafting unique brass material compositions
                                  according to - customer specifications. <br />
                                  - Stainless Steel Turning Grades like 303, 304
                                  etc <br />- Mild Steel Grades like EN 1A (BS
                                  970 230M07) etc <br />- Aluminum Turning
                                  Grades like 1070 etc
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    height: "21",
                                    backgroundColor: "#f0f0f0",
                                  }}
                                >
                                  <strong>Threads:</strong>
                                </td>
                                <td>
                                  - ISO Metric (MM Threads) <br />- British
                                  Standard Threads BSP,BSPT,BSW,BSF,BA <br />
                                  - ASTM threads like NPT,NPTF, UNC, UNF <br />
                                  - ASTM threads like NPT,NPTF, UNC, UNF <br />{" "}
                                  - PG Threads <br />
                                  - ACME Threads <br />
                                  - Edison Threads <br />- or almost any threads
                                  as per custom specification
                                </td>
                              </tr>

                              <tr>
                                <td
                                  style={{
                                    height: "21",
                                    backgroundColor: "#f0f0f0",
                                  }}
                                >
                                  <strong>Finish And Coating:</strong>
                                </td>
                                <td>
                                  - Natural Clean <br />
                                  - Nickel <br />
                                  - Silver
                                  <br />
                                  - Tin <br />
                                  - White, Yellow or Blue Zinc <br />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </section>
                      )}
                    </div>
                  </div>
                </div>
                {showPopup ? (
                  <div className="service-block mb-xl-30 position-relative">
                    <div className="text-start service-caption">
                      <button
                        className="btn btn btn-primary"
                        onClick={handleApplyNowClick}
                      >
                        Request a quotation
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="order-form-pop ">
                    <div
                      className="order-form-overlay "
                      onClick={handleApplyNowClick}
                    ></div>
                    <OrderForm />
                  </div>
                )}
              </section>
            </>
          );
        })}
      </div>
    </>
  );
};

export default InnerAeroSpace;
