import React, { useState, useEffect } from "react";
import bgQuality from "../../Assets/Images/Quality/qualityBg.png";
import quality1 from "../../Assets/Images/Quality/QualityCheck.png";
import quality2 from "../../Assets/Images/Quality/Quality2.png";
import quality3 from "../../Assets/Images/Quality/Quality3.png";
import thumb from "../../Assets/Images/Quality/thumb.png";
import globe from "../../Assets/Images/Quality/globe.png";
import truck from "../../Assets/Images/Quality/truck.png";
import hand from "../../Assets/Images/Quality/hand.png";
import "./Quality.css";
import QualityS from "./QualityS";
import PrivacyPolicy from "../../Assets/Images/Quality/NEW Quality policy  group 6x3.png";
import Translation from "../../i18n";
import certificatePdf from "../../Assets/Images/certificate/certificate.pdf";

function Quality() {
  const { t } = Translation();
  const [left, setLeft] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setLeft((prevLeft) => prevLeft - 500);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const moveSliderItem = () => {
    setLeft((prevLeft) => prevLeft - 500);
  };

  const downloadPdf = () => {
    // Create a temporary link element
    const link = document.createElement("a");
    link.href = certificatePdf;

    // Set the download attribute with a default filename
    link.setAttribute("download", "certificate.pdf");

    // Append the link to the body (required for Firefox)
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Remove the link element after triggering the download
    document.body.removeChild(link);
  };

  return (
    <>
      <section className="quality-first-section">
        <div className="first-bg-img">
          <img src={bgQuality} className="img-fluid" alt="bgQuality" />
          <div className="image-text">
            <h1>{t("OurQuality")}</h1>
          </div>
        </div>
      </section>

      <section className="quality-second-section">
        <div className="container mt-5">
          <div className="row d-flex flex-row align-items-center">
            <div className="qualityPolicyHeading mb-3">
              <h1>
                {t("Quality")} <span>{t("Policy")}</span>
              </h1>
            </div>
            <img src={PrivacyPolicy} alt="img" />
          </div>
          <p onClick={downloadPdf} className="text-center mt-5">
            <a className=" downloadPdf fw-bold">
              View / Download a Copy of Our AS9100D and ISO 9001:2015
              Certificate (PDF)
            </a>
          </p>

          <div className="row mt-5">
            <div className="col-md-8 my-2">
              <div className="quality-section-heading2 mt-2 mb-3">
                <h1>
                  {t("Quality")} <span>{t("assurance")}</span>
                </h1>
              </div>
              <div className="quality-section-para2">
                <p>{t("q-assurance")}</p>
              </div>
            </div>
            <div className="col-md-4 quality-second-section-img my-2">
              <div
                id="carouselExampleIndicators"
                className="carousel slide"
                data-bs-ride="carousel"
                data-bs-interval="3000"
              >
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img
                      src={quality1}
                      className="d-block w-100"
                      alt="quality1"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      src={quality2}
                      className="d-block w-100"
                      alt="quality2"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      src={quality3}
                      className="d-block w-100"
                      alt="quality3"
                    />
                  </div>
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="quality-third-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6 sepolicy">
              <div className="quality-card-section">
                <div className="quality-icon">
                  <img
                    src={thumb}
                    className="img-thumbnail thumb"
                    alt="thumb"
                  />
                </div>
                <div className="quality-card shadow bg-body-tertiary rounded ">
                  <h4>{t("Deffect")} </h4>
                  <p>{t("Deffect-text")}</p>
                </div>
              </div>
            </div>{" "}
            <div className="col-md-6 sepolicy">
              <div className="quality-card-section">
                <div className="quality-icon">
                  <p>
                    <img src={globe} className="img-thumbnail mt-3" alt="img" />
                  </p>
                </div>
                <div className="quality-card shadow bg-body-tertiary rounded  ">
                  <h4>{t("Continuous")}</h4>
                  <p>{t("Continuous-text")}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5 sepolicy">
            <div className="col-md-6 sepolicy">
              <div className="quality-card-section">
                <div className="quality-icon">
                  <p>
                    <img
                      src={truck}
                      className="img-thumbnail mt-3 truck"
                      alt="img"
                    />
                  </p>
                </div>
                <div className="quality-card shadow bg-body-tertiary rounded  ">
                  <h4>{t("Supply-Chain")}</h4>
                  <p>{t("Supply-Chain-text")}</p>
                </div>
              </div>
            </div>{" "}
            <div className="col-md-6 sepolicy">
              <div className="quality-card-section">
                <div className="quality-icon">
                  <p>
                    <img
                      src={hand}
                      className="img-thumbnail hand mt-3"
                      alt="img"
                    />
                  </p>
                </div>
                <div className="quality-card  shadow bg-body-tertiary rounded ">
                  <h4>{t("Integrity")}</h4>
                  <p>{t("Integrity-text")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <QualityS />
    </>
  );
}

export default Quality;
