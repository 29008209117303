import React from "react";
import "./HomeProduct.css";
import TerminalB from "../../Assets/Images/Terminal Blocks.png";
import FastenerF from "../../Assets/Images/Fasteners And Fixings.png";
import ElectricalA from "../../Assets/Images/Electrical Accessories.png";
import Img3 from "../../Assets/Images/img3.png";
import CableG from "../../Assets/Images/Cables And Gland (1).png";
import Earthing from "../../Assets/Images/Earthing Accessories.png";
import CableT from "../../Assets/Images/NewCableTies1.png";
import CastingP from "../../Assets/Images/Casting Product.png";
import Aerospace from "../../Assets/Images/Products/AerospaceProducts.png";
import { Link } from "react-router-dom";
import Translation from "../../i18n";

function Product() {
  const { t, i18n } = Translation();
  return (
    <>
      <section className="product-section ">
        <div className="container">
          <div className="row justify-content-center product-row">
            <h1 className=" mt-3 mb-4">
              {t("our")} <span>{t("Products")}</span>
            </h1>
            <div className="col-md-4  my-4">
              <div className="card" style={{ width: "22rem" }}>
                <img src={Aerospace} className="card-img-top" alt="image" />
                <Link to="/aerospace-industries">
                  <div className="arrow">
                    <span>
                      <i className="fa-solid fa-arrow-right fa-beat "></i>
                    </span>
                  </div>
                </Link>
                <div className="card-body"></div>
                <Link to="/aerospace-industries" className="card-button">
                  {t("AerospaceProducts")}
                </Link>
              </div>
            </div>
            <div className="col-md-4 my-4">
              <div className="card" style={{ width: "22rem" }}>
                <img
                  src={TerminalB}
                  className="card-img-top"
                  alt="TerminalImg"
                />
                <Link to="/terminal-blocks">
                  <div className="arrow">
                    <span>
                      <i className="fa-solid fa-arrow-right fa-beat "></i>
                    </span>
                  </div>
                </Link>
                <div className="card-body"></div>
                <Link to="/terminal-blocks" className="card-button">
                  {t("TerminalBlock")}
                </Link>
              </div>
            </div>
            <div className="col-md-4 my-4">
              <div className="card" style={{ width: "22rem" }}>
                <img src={FastenerF} className="card-img-top" alt="image" />
                <Link to="/fastener-fixing">
                  <div className="arrow">
                    <span>
                      <i className="fa-solid fa-arrow-right fa-beat "></i>
                    </span>
                  </div>
                </Link>
                <div className="card-body"></div>
                <Link to="/fastener-fixing" className="card-button">
                  {t("FastenersAndFixings")}
                </Link>
              </div>
            </div>
            <div className="col-md-4 my-4">
              <div className="card" style={{ width: "22rem" }}>
                <img src={ElectricalA} className="card-img-top" alt="image" />
                <Link to="/electrical-accessories">
                  <div className="arrow">
                    <span>
                      <i className="fa-solid fa-arrow-right fa-beat "></i>
                    </span>
                  </div>
                </Link>
                <div className="card-body"></div>
                <Link to="/electrical-accessories" className="card-button">
                  {t("ElectricalAccessories")}
                </Link>
              </div>
            </div>
            <div className="col-md-4 my-4">
              <div className="card" style={{ width: "22rem" }}>
                <img src={Img3} className="card-img-top" alt="image" />
                <Link to="/brass-electrical-accessories">
                  <div className="arrow">
                    <span>
                      <i className="fa-solid fa-arrow-right fa-beat "></i>
                    </span>
                  </div>
                </Link>
                <div className="card-body"></div>
                <Link
                  to="/brass-electrical-accessories"
                  className="card-button"
                >
                  {t("BrassElectricalAccessories")}
                </Link>
              </div>
            </div>{" "}
            <div className="col-md-4 my-4">
              <div className="card" style={{ width: "22rem" }}>
                <img src={CableG} className="card-img-top" alt="image" />
                <Link to="/CableTies">
                  <div className="arrow">
                    <span>
                      <i className="fa-solid fa-arrow-right fa-beat "></i>
                    </span>
                  </div>
                </Link>
                <div className="card-body"></div>
                <Link to="/CableTies" className="card-button">
                  {t("CableGlands")}
                </Link>
              </div>
            </div>
            <div className="col-md-4 my-4">
              <div className="card" style={{ width: "22rem" }}>
                <img src={Earthing} className="card-img-top" alt="image" />
                <Link to="/coppper-brass-earthing-accessories">
                  <div className="arrow">
                    <span>
                      <i className="fa-solid fa-arrow-right fa-beat "></i>
                    </span>
                  </div>
                </Link>
                <div className="card-body"></div>
                <Link
                  to="/coppper-brass-earthing-accessories"
                  className="card-button"
                >
                  {t("EarthingAccessories")}
                </Link>
              </div>
            </div>{" "}
            <div className="col-md-4 my-4">
              <div className="card" style={{ width: "22rem" }}>
                <img src={CableT} className="card-img-top" alt="image" />
                <Link to="/CableTies2">
                  <div className="arrow">
                    <span>
                      <i className="fa-solid fa-arrow-right fa-beat "></i>
                    </span>
                  </div>
                </Link>
                <div className="card-body"></div>
                <Link to="/CableTies2" className="card-button">
                  {t("CableTies")}
                </Link>
              </div>
            </div>{" "}
            <div className="col-md-4 my-4">
              <div className="card" style={{ width: "22rem" }}>
                <img src={CastingP} className="card-img-top" alt="image" />
                <Link to="/casting-product">
                  <div className="arrow">
                    <span>
                      <i className="fa-solid fa-arrow-right fa-beat "></i>
                    </span>
                  </div>
                </Link>
                <div className="card-body"></div>
                <Link to="/casting-product" className="card-button">
                  {t("CastingProducts")}
                </Link>
              </div>
            </div>{" "}
          </div>
        </div>
      </section>
    </>
  );
}

export default Product;
