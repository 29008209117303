import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore/lite";
import db from "../../firebaseConfig";
import "./Career.css";
import CareerBanner from "../../Assets/Images/CareerHome.png";
import Accordion from "react-bootstrap/Accordion";
import career1 from "../../Assets/Images/career1.png";
import career2 from "../../Assets/Images/career2.png";
import career3 from "../../Assets/Images/career3.png";
import career4 from "../../Assets/Images/career4.png";
import ApplicationForm from "./ApplicationForm";
import { Link } from "react-router-dom";
import Translation from "../../i18n";
import { Helmet } from "react-helmet";

const Career = () => {
  const { t } = Translation();
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(true);
  const [jobPosts, setJobPosts] = useState([]);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [applicationTimestamp, setApplicationTimestamp] = useState(null);

  const postref = collection(db, "ExportNewJobPost");

  const handleApplyNowClick = (postId) => {
    // Get the current date and time
    const appliedOn = new Date();

    // Update the appliedOn field for the selected job post
    setJobPosts((prevJobPosts) =>
      prevJobPosts.map((post) =>
        post.id === postId ? { ...post, appliedOn } : post
      )
    );

    // Set the selected jobId and timestamp in state
    setSelectedJobId(postId);
    setApplicationTimestamp(appliedOn);

    setIsAccordionOpen(!isAccordionOpen);
    setShowPopup((prev) => !prev);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getDocs(postref);
        setJobPosts(
          data.docs?.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }))
        );
        console.log(
          "Fetched data:",
          data.docs.map((doc) => doc.data())
        );
      } catch (err) {
        console.log("Error fetching job Posts", err);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Senwell Exports || Career</title>
        <link rel="canonical" href="https://senwellexports.com/career" />
      </Helmet>
      <div>
        <section className="Career-section-home">
          <div className="Career-section-img">
            <img src={CareerBanner} className="img-fluid" alt="CarrerBanner" />
            <div className="Career-overlay">
              <h2>{t("Careers")}</h2>
            </div>
          </div>
        </section>

        <section className="job-Announcment-section">
          <div className="container">
            <div className="row">
              <div className="seAnnouncement">
                <h1>
                  {t("Job Openings")} <span>{t("Announcement")}</span>
                </h1>
                <p>{t("Announcement-text")}</p>
              </div>
            </div>
          </div>
        </section>
        <div className="SeOpeningPosition">
          <div>
            <div className="col-lg-12 col-md-12 seOpen">
              <h2>{t("Open Positions")}</h2>
            </div>
          </div>
        </div>

        <section className="SeApplyNow">
          {showPopup ? (
            <div className="container">
              <div className="row SedevotedP">
                <p>{t("Open Positions-text")}</p>
              </div>

              <div>
                {jobPosts.map((post) => (
                  <Accordion key={post.id} className="seAccordianB">
                    <Accordion.Item eventKey={post.id}>
                      <Accordion.Header>
                        <h4 className="post-title">{post.Title}</h4>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="row">
                          <div className="col-lg-10">
                            <h4>{post.title}</h4>
                            <strong>{t("Qualifications")}</strong>:{" "}
                            {post.Qualifications}
                            <br />
                            <strong>{t("Experience")}</strong>:{" "}
                            {post.Experience}
                          </div>
                          <div className="col-lg-2 seApplycol">
                            <button
                              type="button"
                              className="seApplyb"
                              onClick={() => handleApplyNowClick(post.id)}
                            >
                              {t("Apply-Now")}
                            </button>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                ))}
              </div>
            </div>
          ) : (
            <div className="order-form-pop">
              <div
                className="order-form-overlay"
                onClick={handleApplyNowClick}
              ></div>
              <ApplicationForm
                jobId={selectedJobId}
                timestamp={applicationTimestamp}
              />
            </div>
          )}
        </section>

        <section className="BDesk-sections">
          <div className="container">
            <div className="row">
              <h1 className="DeskHeading">
                {t("Beyond")} <span>{t("The Desk")}</span>
              </h1>
            </div>

            <div className="DeskVideoContainer">
              <div className="row gx-5 w-100">
                <div className="col-lg-6 col-md-6 col-sm-12 mt-5">
                  <iframe
                    className="iframe"
                    height="315"
                    src="https://www.youtube.com/embed/Ic-Vc7x8WGc?si=vOj0TE8feEhWUNKb"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <h2>{t("Inauguration")}</h2>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-5 ">
                  <iframe
                    className="iframe"
                    height="315"
                    src="https://www.youtube.com/embed/6HBFd4oPEBY?si=Ws4upGZY7J-kolq7"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <h2> {t("Momentum")}</h2>
                </div>
              </div>

              <div className="row gx-5 w-100">
                <div className="col-lg-6 col-md-6 col-sm-12 mt-5">
                  <iframe
                    className="iframe"
                    height="315"
                    src="https://www.youtube.com/embed/YuTGbRUcLXE?si=4uWKo088zCo6eOuv"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <h2>{t("fundation-Day")}</h2>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 mt-5">
                  <iframe
                    className="iframe"
                    height="315"
                    src="https://www.youtube.com/embed/X8XozezuYEg?si=_LyfgfYqJnZknvVz"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <h2>{t("Odyssey")}</h2>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="LifeAt-Sections">
          <div className="container">
            <div className="LifeAt-row">
              <h1>
                {t("Life-at")} <span>Senwell Exports</span>
              </h1>
            </div>

            <div
              id="carouselExample"
              className="carousel slide1"
              data-bs-ride="carousel"
              data-bs-interval="5000"
              data-bs-pause="false"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={career1} className="d-block w-100" alt="Image1" />
                </div>
                <div className="carousel-item">
                  <img src={career2} className="d-block w-100" alt="Image2" />
                </div>
                <div className="carousel-item">
                  <img src={career3} className="d-block w-100" alt="Image3" />
                </div>
                <div className="carousel-item">
                  <img src={career4} className="d-block w-100" alt="Image3" />
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExample"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExample"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </section>
        <div className="JointousToday">
          <div className="container">
            <div className="Join-Us">
              <h2>{t("Waiting")}</h2>
              <div className="SendE">
                <Link to="/contact" className="SendEInner">
                  {t("Send Enquiry")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Career;
