import React, { useState } from "react";
import "./CastingProduct.css";
import ProductData from "../../../../Products.json";
import { Link } from "react-router-dom";

const CastingProduct = () => {
  const [loading, setLoading] = useState(true);
  const AccessrSection = ProductData.sections.find(
    (section) => section.id == 2
  );
  // Extract the relevant data from the found section
  const relevantData =
    AccessrSection.products[7] ||
    [].find((item) => item.id === 208).EarthingProduct;
  const CastingProducts = relevantData.CastingProduct;

  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <div>
      <section className="casting-Section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="brass-subheader-content">
                <h1>Casting Products</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row" style={{ rowGap: "21px" }}>
            <h3 className="mt-4">{relevantData.name}</h3>
            <p>{relevantData.dec}</p>
            {CastingProducts.map((item, index) => (
              <div key={index} className="col-lg-3 col-md-6">
                <div className="service-block-3 mb-xl-30">
                  <div className="PCard">
                    <div className="service-image animate-img">
                      <img
                        src={item.image}
                        alt={item.name}
                        className="inner-fast-img"
                        onLoad={handleImageLoad}
                      />
                    </div>
                    {loading && <div className="loader"></div>}

                    <div className="card-body">
                      <h4 className="card-title">{item.name}</h4>
                      <Link
                        to={`/innner-casting-product/${item.id}`}
                        className="seview"
                      >
                        <button>View Details</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default CastingProduct;
