import React, { useState } from "react";
import "./InnerMetal.css";
import ProductData from "../../../../../Products.json";
import { useParams } from "react-router-dom";
import OrderForm from "../../../OrderForm/OrderForm";

const InnerMetal = () => {
  const [loading, setLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(true);
  const handleApplyNowClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setShowPopup((prev) => !prev);
  };

  const { typeId } = useParams();
  const AccessrSection = ProductData.sections.find(
    (section) => section.id == 2
  );
  // Extract the relevant data from the found section
  const relevantData =
    AccessrSection.products[8] ||
    [].find((item) => item.id === 209).EarthingProduct;
  const MetalEnclosureProduct = relevantData.CastingProduct;
  const product = MetalEnclosureProduct.filter((item) => item.id == typeId);

  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <section className="subInner-section">
      <div className="container">
        <div className="row">
          {product.map((item, index) => (
            <>
              <div key={index} className="col-lg-4 col-md-6">
                <div className="service-block-6 mb-xl-30">
                  {loading && <div className="loader"></div>}
                  <img
                    src={item.image}
                    alt={item.type_name}
                    className="sub-inner-img"
                    onLoad={handleImageLoad}
                  />
                </div>
              </div>

              <div className="col-lg-8 col-md-6">
                <div className="service-block-6 mb-xl-30">
                  <h2>{item.name}</h2>
                  <p>{item.dec}</p>
                  {showPopup ? (
                    <div className="service-block mb-xl-30 position-relative">
                      <div className="text-start service-caption">
                        <button
                          className="btn btn btn-primary"
                          onClick={handleApplyNowClick}
                        >
                          Request a quotation
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="order-form-pop ">
                      <div
                        className="order-form-overlay "
                        onClick={handleApplyNowClick}
                      ></div>
                      <OrderForm />
                    </div>
                  )}
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </section>
  );
};

export default InnerMetal;
