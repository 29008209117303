import React from "react";
import Machine5 from "../../Assets/Images/Infra/NewM-4.png";

const Cnc01machine = () => {
  return (
    <>
      <div className="container">
        {/* Image at the center top */}
        <div className="row justify-content-center mt-5">
          <div className="col-md-6">
            <img
              width="100%"
              height="100%"
              src={Machine5}
              alt="Centered Image"
              className="img-fluid"
            />
          </div>
        </div>

        {/* Table */}
        <div className="row mt-5 d-flex justify-content-center">
          <div className="col-md-8 ">
            <table
              className="table table-striped  table-bordered table-responsive-md "
              cellpadding="5"
              cellspacing="5"
            >
              <thead>
                <tr>
                  <th
                    colSpan="4"
                    style={{
                      textAlign: "center",
                      color: "white",
                      textTransform: "uppercase",
                    }}
                  >
                    CNC Turning Center 01
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>MACHINE MAKE</th>
                  <td>Tsugami</td>
                  <th>Controller</th>
                  <td>Func Oi Mate-TD</td>
                </tr>
                <tr>
                  <th>Chuck Dia</th>
                  <td>Dia 200mm</td>
                  <th>Max Turning Dia</th>
                  <td>Dia 220mm</td>
                </tr>
                <tr>
                  <th>Max Turning Length Between</th>
                  <td>270mm</td>
                  <th>Cross Slide Travel</th>
                  <td>150mm</td>
                </tr>
                <tr>
                  <th>Max Spindle RPM</th>
                  <td>4000RPM</td>
                  <th>Spindle Power </th>
                  <td>5.5KW</td>
                </tr>
                <tr>
                  <th> Tail Stock Support</th>
                  <td>MT3</td>
                  <th>Turrent Stations</th>
                  <td>Hydraulic(8 Stations)</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cnc01machine;
