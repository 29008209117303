import React, { useState } from "react";
import { useParams } from "react-router-dom";
import ProductData from "../../../../../Products.json";
import OrderForm from "../../../OrderForm/OrderForm";

const SubInnerTies2 = () => {
  const [loading, setLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(true);
  const handleApplyNowClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setShowPopup((prev) => !prev);
  };

  const { categoryId, typeId } = useParams();

  const AccessrSection = ProductData.sections.find(
    (section) => section.id == 2
  );

  // Extract the relevant data from the found section
  const relevantData =
    AccessrSection.products[6] ||
    [].find((item) => item.id === 207).EarthingProduct;

  const product = relevantData.CableProduct.filter(
    (product) => product.id == categoryId
  );

  const filteredProduct = product[0].innerProduct.filter(
    (item) => item.type_id == typeId
  );
  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <div>
      <section className="subInner-section">
        <div className="container">
          <div className="row">
            {filteredProduct.map((item, index) => (
              <>
                <div key={index} className="col-lg-4 col-md-6">
                  {loading && <div className="loader"></div>}
                  <div className="service-block-6 mb-xl-30">
                    <img
                      src={item.image}
                      alt={item.type_name}
                      className="sub-inner-img"
                      onLoad={handleImageLoad}
                    />
                  </div>
                </div>
                <div className="col-lg-8 col-md-6">
                  <div className="service-block-6 mb-xl-30">
                    <h2>{item.type_name}</h2>
                    <p>{item.dec}</p>
                    {item.Material && (
                      <p>
                        <b>Material: </b>
                        {item.Material}
                      </p>
                    )}

                    {item.Certification && (
                      <p>
                        <b>Certification: </b>
                        {item.Certification}
                      </p>
                    )}

                    {item.Temperature && (
                      <p>
                        <b>Operating Temperature: </b>
                        {item.Temperature}
                      </p>
                    )}

                    {item.Feature && (
                      <p>
                        <b>Feature: </b>
                        {item.Feature}
                      </p>
                    )}
                    {item.Packaging && (
                      <p>
                        <b>Packaging: </b>
                        <br />
                        {<p>{item.Packaging[0]}</p>}
                        {<p>{item.Packaging[1]}</p>}
                      </p>
                    )}

                    {item.table && (
                      <table
                        className="table table table-bordered"
                        style={{ marginTop: "2%" }}
                      >
                        <tbody>
                          {item.table &&
                            item.table.map((specification, index) => (
                              <tr key={index}>
                                <td>{specification.Length}</td>
                                <td>{specification.Width}</td>
                                <td>{specification.Diameter}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    )}

                    {showPopup ? (
                      <div className="service-block mb-xl-30 position-relative">
                        <div className="text-start service-caption">
                          <button
                            className="btn btn btn-primary"
                            onClick={handleApplyNowClick}
                          >
                            Request a quotation
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="order-form-pop ">
                        <div
                          className="order-form-overlay "
                          onClick={handleApplyNowClick}
                        ></div>
                        <OrderForm />
                      </div>
                    )}
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default SubInnerTies2;
