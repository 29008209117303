import React from "react";
import Machine8 from "../../Assets/Images/Infra/NewM-7.png";

const VmmMachine = () => {
  return (
    <>
      <div className="container">
        {/* Image at the center top */}
        <div className="row justify-content-center mt-5">
          <div className="col-md-6">
            <img
              width="100%"
              height="100%"
              src={Machine8}
              alt="Centered Image"
              className="img-fluid"
            />
          </div>
        </div>

        {/* Table */}
        <div className="row mt-5 d-flex justify-content-center">
          <div className="col-md-8 ">
            <table
              className="table  table-striped table-bordered table-responsive-md "
              cellpadding="5"
              cellspacing="5"
            >
              <thead>
                <tr>
                  <th
                    colSpan="2"
                    style={{ textAlign: "center", color: "white" }}
                  >
                    VIDEO MEASURING MACHINE
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>ID No</td>
                  <td>SE-VMM-001</td>
                </tr>
                <tr>
                  <td>Make</td>
                  <td>ELECTRONICA MECHATRONIC SYSTEMS</td>
                </tr>
                <tr>
                  <td>Machine Model</td>
                  <td>INSPECVISTA 2015 AUTOFOCUS</td>
                </tr>
                <tr>
                  <td>Machine Size</td>
                  <td>X-200MM Y-150MM Z-200MM</td>
                </tr>
                <tr>
                  <td>LC</td>
                  <td>0.001 MM</td>
                </tr>
                <tr>
                  <td>SERIAL NO</td>
                  <td>IV-2015-M-AJ-070</td>
                </tr>
                <tr>
                  <td>CERTIFICATE NO</td>
                  <td>055</td>
                </tr>
                <tr>
                  <td>CALIBRATION DATE</td>
                  <td>03-01-2024</td>
                </tr>
                <tr>
                  <td>CALIBRATION DUE DATE</td>
                  <td>02-01-2025</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default VmmMachine;
