import React, { useState } from "react";
import "./CableTies.css";
import ProductData from "../../../../Products.json";
import { Link } from "react-router-dom";

const CableTies = () => {
  const [loading, setLoading] = useState(true);
  const AccessrSection = ProductData.sections.find(
    (section) => section.id == 2
  );

  const relevantData =
    AccessrSection.products[4] || [].find((item) => item.id === 205);
  const CableData = relevantData.CableProduct[0].BrassCopperProduct;

  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <div>
      <section className="Cable-gland-Section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="brass-subheader-content">
                <h1>Cable Glands</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row" style={{ rowGap: "21px" }}>
            <h3 className="my-5">{relevantData.name}</h3>
            <p>
              <b>Materials: </b>
              {relevantData.Materials}
            </p>
            <p>
              <b>Finishes: </b>
              {relevantData.Finishes}
            </p>
            <p>
              <b>Entry Threads: </b>
              {relevantData.EntryThreads}
            </p>
            <p>
              <b>Earthing / Grounding: </b>
              {relevantData.EarthingGrounding}
            </p>
            {CableData &&
              CableData?.map((product) => (
                <div key={product.type_id} className="col-lg-4 col-md-6">
                  <div className="inner-box mb-xl-30">
                    <div className="animation-img">
                      <img
                        src={product.image}
                        alt={product.type_name}
                        className="img-fit"
                        onLoad={handleImageLoad}
                      />
                    </div>
                    {loading && <div className="loader"></div>}
                    <div className="service-caption">
                      <div className="left-side">
                        <h4 className="fw-600 no-margin">
                          {product.type_name}
                        </h4>
                      </div>
                      <Link
                        to={
                          product.type_id == 10
                            ? `/Inner-cableTies/${product.type_id}`
                            : `/sub-Inner-cableTies/${product.type_id}`
                        }
                        className="arr-button"
                      >
                        <i className="fa-solid fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default CableTies;
