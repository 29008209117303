import React, { useState } from "react";
import "./EarthinAccesories.css";
import ProductData from "../../../../../Products.json";
import { Link } from "react-router-dom";

const EarthinAccesories = () => {
  const [loading, setLoading] = useState(true);
  const AccessrSection = ProductData.sections.find(
    (section) => section.id == 2
  );

  const relevantData =
    AccessrSection.products[5] ||
    [].find((item) => item.id === 206).EarthingProduct;
  const product = relevantData.EarthingProduct;
  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <div>
      <section className="Earthing-Section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="brass-subheader-content">
                <h1>{relevantData.name}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <div className="row">
          <h3 className="my-5">{relevantData.name}</h3>

          {product.map((product) => (
            <div key={product.type_id} className="col-lg-4 col-md-6">
              <div className="inner-box mb-xl-30">
                <div className="animation-img">
                  <img
                    src={product.image}
                    alt={product.type_name}
                    className="img-fit"
                    onLoad={handleImageLoad}
                  />
                </div>
                {loading && <div className="loader"></div>}
                <div className="service-caption">
                  <div className="left-side">
                    <h4 className="fw-600 no-margin">{product.type_name}</h4>
                  </div>
                  <Link
                    to={`/inner-coppper-brass-earthing-accessories/${product.type_id}`}
                    className="arr-button"
                  >
                    <i className="fa-solid fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EarthinAccesories;
