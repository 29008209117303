import React, { useState } from "react";
import "./InnerTerminal.css";
import { useParams } from "react-router-dom";
import jsonData from "../../../../Products.json";
import OrderForm from "../../OrderForm/OrderForm";

const InnerTerminal = () => {
  const [showPopup, setShowPopup] = useState(true);
  const [loading, setLoading] = useState(true);

  const handleApplyNowClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setShowPopup((prev) => !prev);
  };
  const { typeId } = useParams();
  const electricalSection = jsonData.sections.find(
    (section) => section.id === 2
  );
  if (!electricalSection) {
    return <div>Electrical section not found</div>;
  }

  // Find the type based on typeId
  const selectedType = electricalSection.products[0].types.find(
    (type) => type.type_id === Number(typeId)
  );
  if (!selectedType) {
    return <div>Type not found</div>;
  }

  const handleImageLoad = () => {
    setLoading(false);
  };
  return (
    <div>
      <section className="InnerTerminal">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              {loading && <div className="loader"></div>}
              <img
                src={selectedType.image}
                alt="img"
                className="inner-img-fit"
                onLoad={handleImageLoad}
              />
            </div>

            <div className="col-lg-8 col-md-6">
              <div className="service-block-6 mb-xl-30">
                <h2>{selectedType.type_name}</h2>
                <p>{selectedType.description}</p>

                {showPopup ? (
                  <div className="service-block mb-xl-30 position-relative">
                    <div className="text-start service-caption">
                      <button
                        className="btn btn btn-primary"
                        onClick={handleApplyNowClick}
                      >
                        Request a quotation
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="order-form-pop">
                    <div
                      className="order-form-overlay "
                      onClick={handleApplyNowClick}
                    ></div>
                    <OrderForm />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default InnerTerminal;
