import React from "react";
import "./Fastener.css";
import ProductData from "../../../../Products.json";
import { Link } from "react-router-dom";

const Fastener = () => {
  const fastnerSection = ProductData.sections.find(
    (section) => section.id == 2
  );

  const relevantData =
    fastnerSection.products[1] || [].find((item) => item.id === 202);
  const InsideRelevent = relevantData.insideProduct;

  return (
    <div>
      <section className="Fastener-Section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="fastener-subheader-content">
                <h1>Fasteners and Fixing</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="fastener-product-section">
        <div className="container">
          <div className="row">
            <div className="container">
              <div className="product my-4">
                <h3 className="fw-600">{relevantData.name}</h3>
                <p>{relevantData.smallD}</p>
                <p>
                  <b>Threads:</b> {relevantData.thread}
                </p>
                <p>
                  <b>Material:</b>
                  {relevantData.Material}
                </p>
              </div>

              <section>
                <div className="container">
                  <div className="row" style={{ rowGap: "26px" }}>
                    {InsideRelevent &&
                      InsideRelevent.map((product) => (
                        <div
                          key={product.type_id}
                          className="col-lg-4 col-md-6"
                        >
                          <div className="inner-box mb-xl-30">
                            <div className="animation-img">
                              <img
                                src={product.image}
                                alt={product.type_name}
                                className="img-fit"
                              />
                            </div>
                            <div className="service-caption">
                              <div className="left-side">
                                <h4 className="fw-600 no-margin">
                                  {product.type_name}
                                </h4>
                              </div>
                              <Link
                                to={`/fastener-fixing/${product.type_id}`}
                                className="arr-button"
                              >
                                <i className="fa-solid fa-arrow-right"></i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Fastener;
