import React from "react";
import VmcAxis3 from "../../Assets/Images/Infra/Vmc3axis.png";

const VmmMachine = () => {
  return (
    <>
      <div className="container">
        {/* Image at the center top */}
        <div className="row justify-content-center mt-5">
          <div className="col-md-6">
            <img
              width="100%"
              height="100%"
              src={VmcAxis3}
              alt="Centered Image"
              className="img-fluid"
            />
          </div>
        </div>

        {/* Table */}
        <div className="row mt-5 d-flex justify-content-center">
          <div className="col-md-8 ">
            <table
              className="table table-striped   table-bordered table-responsive-md "
              cellpadding="5"
              cellspacing="5"
            >
              <thead>
                <tr>
                  <th
                    colSpan="4"
                    style={{
                      textAlign: "center",
                      color: "white",
                      textTransform: "uppercase",
                    }}
                  >
                    Vertical Machinig Center 3-Axis
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <th>MACHINE MAKE</th>
                  <td>HASS</td>
                  <th>Controller</th>
                  <td>HASS Oi-MF</td>
                </tr>
                <tr>
                  <th>Spindle Taper</th>
                  <td>BT40</td>
                  <th>Bed Size</th>
                  <td>914X356</td>
                </tr>
                <tr>
                  <th>Max Spindle RPM</th>
                  <td>10000RPM</td>
                  <th>X-axis Travel</th>
                  <td>760mm</td>
                </tr>
                <tr>
                  <th> Spindle Power</th>
                  <td>22.4KW</td>
                  <th>Y-axis Travel</th>
                  <td>400mm</td>
                </tr>
                <tr>
                  <th> Total Stations/Magzine</th>
                  <td>30+1</td>
                  <th>Z-axis Travel</th>
                  <td>500mm</td>
                </tr>
                <tr>
                  <th> Max Load on Table</th>
                  <td>1361Kg</td>
                  <th>Max Diameter of toll</th>
                  <td>64X279</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default VmmMachine;
