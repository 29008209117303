import React,{useState} from "react";
import { Link, useParams } from "react-router-dom";
import ProductData from "../../../../../Products.json";
import "./InnerBrass.css";

const InnerBrass = () => {
  const [loading, setLoading] = useState(true);

  const { typeId } = useParams();
  const AccessrSection = ProductData.sections.find(
    (section) => section.id == 2
  );
  const relevantData =
    AccessrSection.products[3] || [].find((item) => item.id === 204);
  const filteredProduct = relevantData.BrassProduct.filter(
    (product) => product.type_id == typeId
  );
  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <div>
      <section className="brass-copper-Section">
        <div className="container">
          <div className="row" style={{ rowGap: "20px" }}>
            <div className="col-12">
              <div className="brass-copper-subheader-content">
                <h1>{filteredProduct[0].type_name}</h1>{" "}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <h3 className="my-5">{filteredProduct[0].type_name}</h3>
            {filteredProduct[0].BrassCopperProduct.map((item, index) => (
              <div key={index} className="col-lg-3 col-md-6">
                <div className="service-block-3 mb-xl-30">
                  <div className="PCard">
                    <div className="service-image animate-img">
                      <img
                        src={item.image}
                        alt={item.type_name}
                        className="inner-fast-img"
                        onLoad={handleImageLoad}
                      />
                    </div>
                    {loading && <div className="loader"></div>}

                    <div className="card-body">
                      <h4 className="card-title">{item.type_name}</h4>

                      <Link
                        to={`/sub-inner-brass/${typeId}/${item.type_id}`}
                        className="seview"
                      >
                        <button>View Details</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default InnerBrass;
