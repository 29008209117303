import React, { useState, useEffect } from "react";
import "./InnerAccess.css";
import ProductData from "../../../../../Products.json";
import { Link, useParams } from "react-router-dom";

const InnerAccess = () => {
  const [loading, setLoading] = useState(true);
  const { typeId } = useParams();

  const InnerAccessSection = ProductData.sections.find(
    (section) => section.id == 2
  );
  const [innerAccessData, setInnerAccessData] = useState(null);

  useEffect(() => {
    const relevantData =
      InnerAccessSection.products[2] || [].find((item) => item.id === 203);

    let insideAccess = relevantData.insideProduct.find(
      (product) => product.type_id === Number(typeId)
    );

    setInnerAccessData(insideAccess);
  }, [InnerAccessSection.products, typeId]);
  if (!innerAccessData) {
    return <p>Loading...</p>;
  }
  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <div>
      <section className="Inner-E-Access-Section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="Inner-E-Access-subheader-content">
                <h1>{innerAccessData.type_name2}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="Inn-fas-section2">
        <div className="container">
          <div className="product my-4">
            <h3 className="fw-600">{innerAccessData.type_name2}</h3>
            <p>{innerAccessData.dec}</p>
            <p>
              {innerAccessData.Material && <strong> Material:</strong>}

              {innerAccessData.Material}
            </p>
            <p>
              {innerAccessData.Sizes && <strong>Size:</strong>}
              {innerAccessData.Sizes}
            </p>
            <p>
              {innerAccessData.Finish && <strong>Finish:</strong>}
              {innerAccessData.Finish}
            </p>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row" style={{ rowGap: "21px" }}>
            {innerAccessData.InsideTinnedProduct.map((item, index) => (
              <div key={index} className="col-lg-3 col-md-6">
                <div className="service-block-3 mb-xl-30">
                  <div className="PCard">
                    <div className="service-image animate-img">
                      <img
                        src={item.image}
                        alt={item.type_name}
                        className="inner-fast-img"
                        onLoad={handleImageLoad}
                      />
                    </div>
                    {loading && <div className="loader"></div>}

                    <div className="card-body">
                      <h4 className="card-title">{item.type_name}</h4>

                      <Link
                        to={`/subinner-accessories/${typeId}/${item.type_id}`}
                        className="seview"
                      >
                        <button>View Details</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default InnerAccess;
