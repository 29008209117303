import React, { useState } from "react";
import "../TerminalBlock/TerminalBlock.css";
import { Link } from "react-router-dom";
import jsonData from "../../../../Products.json";

const TerminalBlock = () => {
  const [loading, setLoading] = useState(true);

  const electricalSection = jsonData.sections.find(
    (section) => section.id === 2
  );

  if (!electricalSection) {
    return <div>Electrical section not found</div>;
  }
  const typesData = electricalSection.products[0].types;
  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <div>
      <section className="Electrical-Section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="El-subheader-content">
                <h1>Terminal Blocks</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="Terminal-section">
        <div className="container">
          <h1>Terminal Blocks</h1>

          <div className="row" style={{ rowGap: "21px" }}>
            {typesData.map((type) => (
              <div key={type.type_id} className="col-lg-4 col-md-6">
                <div className="inner-box mb-xl-30">
                  <div className="animation-img">
                    <img
                      src={type.image}
                      alt="img"
                      className="fastner-img-fit"
                      onLoad={handleImageLoad}
                    />
                  </div>
                  {loading && <div className="loader"></div>}
                  <div className="service-caption">
                    <div className="left-side">
                      <h4 className="fw-600 no-margin">
                        <h3>{type.type_name}</h3>
                      </h4>
                    </div>
                    <Link
                      to={`/inner-terminal-blocks/${type.type_id}`}
                      className="arr-button"
                    >
                      <i className="fa-solid fa-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default TerminalBlock;
