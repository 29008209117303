import React from "react";
import Machine10 from "../../Assets/Images/Infra/dhgmachine.png";

const DhgMachine = () => {
  return (
    <>
      <div className="container">
        {/* Image at the center top */}
        <div className="row justify-content-center mt-5">
          <div className="col-md-6">
            <img
              width="100%"
              height="100%"
              src={Machine10}
              alt="Centered Image"
              className="img-fluid"
            />
          </div>
        </div>

        {/* Table */}
        <div className="row mt-5 d-flex justify-content-center">
          <div className="col-md-8 ">
            <table
              className="table  table-striped table-bordered table-responsive-md "
              cellpadding="5"
              cellspacing="5"
            >
              <thead>
                <tr>
                  <th
                    colSpan="2"
                    style={{ textAlign: "center", color: "white" }}
                  >
                    Digital Height Gauge
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>ID No</td>
                  <td>SE-DHG-02</td>
                </tr>
                <tr>
                  <td>Make</td>
                  <td>BAKER</td>
                </tr>
                <tr>
                  <td>Machine Model</td>
                  <td>INSPECVISTA 2015 AUTOFOCUS</td>
                </tr>
                <tr>
                  <td>Machine Size</td>
                  <td>0 - 300 mm</td>
                </tr>
                <tr>
                  <td>LC</td>
                  <td>0.01 mm</td>
                </tr>
                <tr>
                  <td>SERIAL NO</td>
                  <td>2302240062</td>
                </tr>
                <tr>
                  <td>CERTIFICATE NO</td>
                  <td>WWS/24-25/04/06</td>
                </tr>
                <tr>
                  <td>CALIBRATION DATE</td>
                  <td>4-Jan-24</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default DhgMachine;
