import React from "react";
import news1 from "../../Assets/Images/news/newsBannersss.webp";
import "./News.css";
import { Link } from "react-router-dom";

function News() {
  return (
    <>
      <img src={news1} class="img-fluid w-100" alt="Responsive image" />

      <section className="news-card-section">
        <div className="container">
          <div className="row">
            <div className="col-md-4 my-3">
              <Link
                className="blogLinks"
                to="/news/GE-Aerospace-advances-the-development-collaboration-with-NASA"
              >
                <div className="Exportcard blogbox">
                  <div className="px-4 pt-4">
                    <img
                      src="https://www.geaerospace.com/news/sites/default/files/inline-images/2024_RISE_Testing_PEx%20baseline_Peebles-5886%20v2.JPG"
                      alt="image"
                      className="blog-ig"
                    />
                  </div>
                  <div className="card-body mnhgt-175 px-4">
                    <p className="card-text">21 June, 2024</p>
                    <h4 className="card-title text-dark">
                      GE Aerospace advances the development of hybrid electric
                      engines in collaboration with NASA.
                    </h4>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default News;
