import React from "react";
import "./Csr.css";
import CsrBanner from "../../Assets/Images/CSR/csrbanner.png";
import greenM from "../../Assets/Images/CSR/GreenManufacturing1.png";
import Health from "../../Assets/Images/CSR/Health&Sefty.png";
import Social from "../../Assets/Images/CSR/SocialInitiative.png";
import Moto from "../../Assets/Images/CSR/Moto.png";
import Mission from "../../Assets/Images/CSR/Mission.png";
import Vision from "../../Assets/Images/CSR/Vision.png";
import HealthCare from "../../Assets/Images/CSR/HealthCare1.png";
import Education from "../../Assets/Images/CSR/Educations1.png";
import Sustainibility from "../../Assets/Images/CSR/Sustainibility1.png";
import Art from "../../Assets/Images/CSR/Art & Culture1.png";
import SocialIssues from "../../Assets/Images/CSR/Social Issues1.png";
import Development from "../../Assets/Images/CSR/Development1.png";
import Env1 from "../../Assets/Images/CSR/env1.png";
import Env2 from "../../Assets/Images/CSR/env2.png";
import Env3 from "../../Assets/Images/CSR/env3.png";
import Env4 from "../../Assets/Images/CSR/env4.png";
import Translation from "../../i18n";
import { Helmet } from "react-helmet";

const Csr = () => {
  const { t } = Translation();
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Senwell Exports || CSR</title>
        <link rel="canonical" href="https://senwellexports.com/csr" />
      </Helmet>
      <div className="csr-section-home">
        <div className="home-csr-img">
          <img src={CsrBanner} alt="CsrBanner" />
        </div>
      </div>

      <div className="crs-main">
        <div className="container">
          <div className="row">
            <div className="col-md-12 csr-block">
              <h2>
                <strong>{t("Corporate-Social-Responsibility")}</strong>
              </h2>
              <p>{t("Corporate-Social-Responsibility-text")}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="crs-moto">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 crs-moto-inner">
              <div className="crs-img-framed">
                <img src={Moto} alt="Moto" />
              </div>

              <div className="crs-inner-text">
                <h3>{t("Our-Moto")}</h3>
                <p>{t("moto-text")}</p>
              </div>
            </div>

            <div className="col-lg-4 crs-moto-inner">
              <div className="crs-img-framed">
                <img src={Mission} alt="Mission" />
              </div>
              <div className="crs-inner-text">
                <h3>{t("our-mission")}</h3>
                <p>{t("mision-text")}</p>
              </div>
            </div>
            <div className="col-lg-4 crs-moto-inner">
              <div className="crs-img-framed">
                <img src={Vision} alt="Vision" />
              </div>

              <div className="crs-inner-text">
                <h3>{t("Our-vision")}</h3>
                <p>{t("vision-text")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="se-success-section">
        <div className="container">
          <div className="row" style={{ marginBottom: "30px" }}>
            <div
              className="col-12 col-lg-6"
              style={{ backgroundColor: "#fff" }}
            >
              <div className="health-safety">
                <h4>{t("Commitment")}</h4>
                <p>{t("Commitment-text")}</p>
              </div>
            </div>
            <div className="col-12 col-lg-6 px-0">
              <div className="healt-safety">
                <img
                  src={greenM}
                  alt="greenM"
                  className="healthimg-fluid w-100"
                />
              </div>
            </div>
          </div>

          <div className="row" style={{ marginBottom: "30px" }}>
            <div className="col-12 col-lg-6 px-0">
              <div className="health-safety">
                <img
                  src={Health}
                  alt="Health"
                  className="healthimg-fluid w-100"
                />
              </div>
            </div>
            <div
              className="col-12 col-lg-6"
              style={{ backgroundColor: "#fff" }}
            >
              <div className="health-safety">
                <h4>{t("Health")}</h4>
                <p>{t("Health-text")}</p>
              </div>
            </div>
          </div>

          <div className="row ">
            <div
              className="col-12 col-lg-6"
              style={{ backgroundColor: "#fff" }}
            >
              <div className="health-safety">
                <h4>{t("Social-Initiatives")}</h4>
                <p>{t("Social-Initiatives-text")}</p>
              </div>
            </div>
            <div className="col-12 col-lg-6 px-0">
              <div className="healt-safety">
                <img
                  src={Social}
                  alt="Social"
                  className="healthimg-fluid w-100"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="csr-key-area">
        <div className="container">
          <div className="row" style={{ marginBottom: "30px" }}>
            <h3 className="csr-key-area-heading">{t("Foundation")}</h3>
            <div className="col-lg-2">
              <div className="csr-focus-col">
                <img src={HealthCare} alt="HealthCare" className="csr-img" />
                <h3>{t("Healthcare")}</h3>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="csr-focus-col">
                <img src={Education} alt="Education" className="csr-img" />
                <h3>{t("Education")}</h3>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="csr-focus-col">
                <img
                  src={Sustainibility}
                  alt="Sustainibility"
                  className="csr-img"
                />
                <h3>{t("Sustainibility")}</h3>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="csr-focus-col">
                <img src={Art} alt="Art" className="csr-img" />
                <h3>{t("art")}</h3>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="csr-focus-col">
                <img
                  src={SocialIssues}
                  alt="SocialIssues"
                  className="csr-img"
                />
                <h3>{t("Social-Issues")}</h3>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="csr-focus-col">
                <img src={Development} alt="Development" className="csr-img" />
                <h3>{t("Community-Development")}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="se-commited-section">
        <div className="container">
          <div className="row" style={{ marginBottom: "20px" }}>
            <div className="col-lg-4">
              <img src={Env1} alt="Env1" className="SeEnvImage" />
            </div>
            <div className="col-lg-8 se-commited-col">
              <h3 className="SeReduce">{t("Minimize-our")}</h3>

              <div className="fusion-text fusion-text-3 SeReduce-inner">
                <ul>
                  <li>{t("minimizi-out-text")}</li>
                  <li>{t("minimizi-out-text1")}</li>
                  <li>{t("minimizi-out-text2")}</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row" style={{ marginBottom: "20px" }}>
            <div className="col-lg-8 se-commited-col">
              <h3 className="SeReduce">{t("placing")}</h3>

              <div className="fusion-text fusion-text-3 SeReduce-inner">
                <ul>
                  <li>{t("Dedicatedly")}</li>
                  <li>{t("Empowering")}</li>
                  <li>{t("Championing")}</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <img src={Env2} alt="right-img" className="SeEnvImage" />
            </div>
          </div>

          <div className="row" style={{ marginBottom: "20px" }}>
            <div className="col-lg-4">
              <img src={Env3} alt="Env3" className="SeEnvImage" />
            </div>
            <div className="col-lg-8 se-commited-col">
              <h3 className="SeReduce">{t("dedicated")}</h3>

              <div className="fusion-text fusion-text-3 SeReduce-inner">
                <ul>
                  <li>{t("dedicated-text")}</li>
                  <li>{t("dedicated-text1")}</li>
                  <li>{t("dedicated-text2")}</li>
                  <li>{t("dedicated-text3")}</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row" style={{ marginBottom: "20px" }}>
            <div className="col-lg-8 se-commited-col">
              <h3 className="SeReduce">{t("Advocate")}</h3>

              <div className="fusion-text fusion-text-3 SeReduce-inner">
                <ul>
                  <li>{t("Advocate-text")}</li>
                  <li>{t("Advocate-text1")}</li>
                  <li>{t("Advocate-text2")}</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <img src={Env4} alt="Env4" className="SeEnvImage" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Csr;
