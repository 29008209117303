import React, { useEffect } from "react";
import "./Home.css";
import Products1 from "../Assets/Images/NewProduct.png";
import ComapnyLogo from "../Assets/Images/HomeBanner6.png";
import GlobalLogo from "../Assets/Images/HomeBanner7.png";
import WelcomeImg1 from "../Assets/Images/finalwelcomeimage1.png";
import WelcomeImg from "../Assets/Images/Welcometo.png";
import WelcomeImg3 from "../Assets/Images/Welcome3.png";
import RandDImg from "../Assets/Images/R&D.png";
import manuVideo from "../Assets/Images/LatestVideo.mp4";
import Icon1 from "../Assets/Images/icon1.png";
import Icon2 from "../Assets/Images/icon2.png";
import Icon3 from "../Assets/Images/icon3.png";
import Icon4 from "../Assets/Images/icon4.png";
import Icon5 from "../Assets/Images/icon5.png";
import Product from "./HomeProduct/HomeProduct";
import Slider from "./Slider/Slider";
import service1 from "../Assets/Images/services/s1.png";
import service2 from "../Assets/Images/services/s2.png";
import service4 from "../Assets/Images/services/s4.png";
import service5 from "../Assets/Images/services/s5.png";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min.js";
import Manufac from "../Assets/Images/manufacturing process.png";
import Translation from "../i18n";
import { Helmet } from "react-helmet";

const Home = () => {
  const { t } = Translation();
  useEffect(() => {
    // Initialize the Bootstrap carousel when the component mounts
    const carousel = new bootstrap.Carousel(
      document.getElementById("carouselExampleFade"),
      {
        interval: 4000,
        pause: false,
      }
    );

    return () => {
      carousel.dispose();
    };
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Senwell Exports || Home</title>
        <link rel="canonical" href="https://senwellexports.com/" />
      </Helmet>
      <div>
        <section className="home-carousel-section">
          <div
            id="carouselExampleFade"
            className="carousel slide carousel-fade"
            data-bs-ride="carousel"
            data-bs-interval="4000"
            data-bs-pause="false"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src={Products1}
                  className="d-block w-100 carousel-img"
                  alt="products1"
                />
                <div className="carousel-text">
                  <h2>Global Exporter of Ferrous and Non-Ferrous Metals</h2>
                  <h4>
                    Today our products reach as many as 56 countries and command
                    a strong presence in USA, Europe, and the Middle East.
                  </h4>
                </div>
              </div>
              <div className="carousel-item">
                <img
                  src={ComapnyLogo}
                  className="d-block w-100 carousel-img"
                  alt="companyLogo"
                />
                <div className="carousel-text">
                  <h2>Customized Manufacturing of Precision Components</h2>
                  <h4>
                    Manufacturing Components As Per Your Design & Requirements.
                  </h4>
                </div>
              </div>
              <div className="carousel-item">
                <img
                  src={GlobalLogo}
                  className="d-block w-100 carousel-img"
                  alt="GlobalLogo"
                />
                <div className="carousel-text">
                  <h2>
                    Senwell Exports <br />
                    Private Limited
                  </h2>
                  <h4>
                    Attaining an AS 9100D certification, our main focus is to
                    deliver defect-free and highest quality products which help
                    us to build a long-term relationship with our customers.
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="wellcomese" id="top_wellcomese">
          <div className="container">
            <div className="row">
              <div className="wellcomese-heading mt-2">
                <h2>{t("welcome")}</h2>
                <h2 className="sec-head">Senwell Exports</h2>
              </div>
              <div className="col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-12 col-12">
                <div className="WecomesectionTitle"></div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div
                  className="col-md-4 welcome-to-section-img my-2"
                  style={{ height: "100%", width: "100%" }}
                >
                  <div
                    id="carouselExampleIndicators"
                    className="carousel slide"
                    data-bs-ride="carousel"
                    data-bs-interval="3000"
                  >
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <img
                          src={WelcomeImg}
                          className="d-block w-100"
                          alt="quality1"
                        />
                      </div>
                      <div className="carousel-item">
                        <img
                          src={WelcomeImg1}
                          className="d-block w-100"
                          alt="quality2"
                        />
                      </div>

                      <div className="carousel-item">
                        <img
                          src={WelcomeImg3}
                          className="d-block w-100"
                          alt="quality1"
                        />
                      </div>
                    </div>
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-bs-target="#carouselExampleIndicators"
                      data-bs-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-bs-target="#carouselExampleIndicators"
                      data-bs-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-12 col-sm-12 right-txt mt-3">
                <p>{t("nurtured")}</p>
                <p>{t("nurtured1")}</p>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="ProductRangeMain">
            <div className="container  home-service-container">
              <h1 className="mt-4 mb-4">{t("Ourproduct-range")}</h1>
              <div className="row home-row shadow ">
                <div className="col-md-3 our-product mt-5 ">
                  <div className="services text-center">
                    <span>
                      <img src={Icon1} className="img-thumbnail" alt="Icon1" />
                    </span>
                  </div>
                  <h2>{t("Aerospace")}</h2>
                  <p>{t("AeroDec")}</p>
                </div>

                <div className="col-md-3 our-product  mt-5 ">
                  <div className="services  text-center">
                    <span>
                      <img src={Icon3} className="img-thumbnail" alt="Icon3" />
                    </span>
                  </div>
                  <h2>{t("Oilandgas")}</h2>
                  <p>{t("OilDec")}</p>
                </div>

                <div className="col-md-3 our-product mt-5 ">
                  <div className="services  text-center">
                    <span>
                      <img src={Icon2} className="img-thumbnail" alt="Icon2" />
                    </span>
                  </div>
                  <h2>{t("energy")}</h2>
                  <p>{t("energyDec")}</p>
                </div>
                <div className="col-md-3 our-product  mt-5 ">
                  <div className="services  text-center">
                    <span>
                      <img src={Icon5} className="img-thumbnail" alt="Icon4" />
                    </span>
                  </div>
                  <h2>{t("construction")}</h2>
                  <p>{t("consDec")}</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="manufacturing">
          <h1 className=" mt-3 mb-5">
            {t("our")} <span> {t("process")}</span>
          </h1>
          <video className="w-100" autoPlay loop muted>
            <source src={manuVideo} type="video/mp4" />
          </video>
        </section>

        <section>
          <div className="our-Manufactring-img-section-none   my-2 mt-2 mb-2">
            <h1 className=" mt-3 mb-2">
              {t("our")} <span> {t("process")}</span>
            </h1>
            <img src={Manufac} className="img-fluid" alt="img" />
          </div>
        </section>

        <Product />
        {/*  <section className="RandDSection">
          <div className="RandD-container">
            <div className="row">
              <div className="col-md-6 RandD-Main">
                <h1>{t("RAndDDepartment")}</h1>
                <div className="randdleftimg">
                  <img src={RandDImg} alt="r&dImg" />
                </div>
              </div>
              <div className="col-md-6 ">
                <div className="randdrightContent">
                  <div className="randd-text">
                    <p>{t("RAndDText")}</p>
                    <p>{t("RAndDText1")}</p>
                  </div>
                  <div className="readMoreBut">
                    <Link
                      type="button"
                      to="/infrastructure"
                      className="Read-MoreInner"
                    >
                      {t("ReadMore")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
  </section>*/}

        <section className="container our-services">
          <h1 className="mt-4 mb-3 fw-bold">
            {t("our")} <span>{t("services")}</span>
          </h1>
          <div className="container fw-bold mt-5">
            <Link className="box" to="/service#oem-manufacturing">
              <img src={service2} alt="Image 2" />
              <div className="text-overlay">
                <p>{t("OEMManufacturing")}</p>
              </div>
            </Link>

            <Link className="box" to="/service#assembly-hub">
              <img src={service1} alt="Image 1" />
              <div className="text-overlay ">
                <p>{t("AssemblyHub")}</p>
              </div>
            </Link>
          </div>
          <div className="container fw-bold ">
            <Link className="box" to="/service#contraction-manufacturing">
              <img src={service4} alt="Image1" />
              <div className="text-overlay">
                <p>{t("ContractManufacturing")}</p>
              </div>
            </Link>

            <Link className="box" to="/service#offshore-manufacturing">
              <img src={service5} alt="Image 2" />
              <div className="text-overlay">
                <p>{t("OffshoreManufacturing")}</p>
              </div>
            </Link>
          </div>
        </section>
        <Slider />
      </div>
    </>
  );
};

export default Home;
