import React from "react";
import "./Footer.css";
import footLogo from "../../Assets/Images/footerLogo.png";
import finallogo from "../../Assets/Images/finalsenwellgrouplogo.png";
import { Link, useNavigate } from "react-router-dom";
import Translation from "../../i18n";

function Footer() {
  const navigate = useNavigate();
  const { t } = Translation();

  return (
    <>
      <section className="footer-section text-white  mt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="footer-first-section">
                <div className="footer-logo mt-2 ">
                  <div className="logo ">
                    <img
                      src={finallogo}
                      className="img-thumbnail"
                      alt="Footer"
                    />
                    <h4 className="mx-2">Senwell Exports Private Limited</h4>
                  </div>
                </div>
                <div className="footer-first-content">
                  <p>{t("footertext")}</p>
                </div>
                <h6>{t("find-us")}</h6>
                <div className="footer-social-media">
                  <a
                    href=" https://www.facebook.com/profile.php?id=100068983571168"
                    target="_blank"
                  >
                    <i className="fab fa-facebook"></i>
                  </a>
                  <a href="https://twitter.com/SenwellPune" target="_blank">
                    <i className="fa-brands fa-x-twitter"></i>{" "}
                  </a>
                  <a
                    href="https://www.instagram.com/senwellgroup/"
                    target="_blank"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/senwell-exports-private-limited/"
                    target="_blank"
                  >
                    <i className="fab fa-linkedin"></i>
                  </a>
                  <a
                    href="https://www.youtube.com/@senwellgroup"
                    target="_blank"
                  >
                    <i className="fab fa-youtube"></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-md-3 ">
              <div className="footer-second-section">
                <div className="footer-menu">
                  <h6>{t("quick-links")}</h6>

                  <p onClick={() => navigate("/")}>{t("Home")}</p>
                  <p onClick={() => navigate("/about")}>{t("AboutUs")}</p>
                  <p onClick={() => navigate("/terminal-blocks")}>
                    {t("Products")}
                  </p>
                  <p onClick={() => navigate("/quality")}>{t("Quality")}</p>
                  <p onClick={() => navigate("/service")}>{t("services")}</p>
                  <p onClick={() => navigate("/blog")}>{t("blogs")}</p>
                  <p onClick={() => navigate("/career")}>{t("Career")}</p>
                  <p onClick={() => navigate("/contact")}>{t("Contact_us")}</p>
                </div>
              </div>
            </div>
            <div className="col-md-3 ">
              <div className="footer-third-section">
                <div className="footer-industries-menu ">
                  <h6>{t("Industries")}</h6>

                  <p onClick={() => navigate("/aerospace-industries")}>
                    {t("Aerospace")}
                  </p>
                  <p onClick={() => navigate("/oil-gas")}>{t("OilAndGas")}</p>
                  <p onClick={() => navigate("/electrical-products")}>
                    {t("Energy")}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-3 ">
              <div className="footer-fourth-section">
                <div className="footer-contact">
                  <h6>Contact Us</h6>
                  <p>
                    Corporate Office :- Gera's Imperium Alpha, <br />
                    Near EON IT Park, <br />
                    Kharadi, Pune, Maharashtra, <br /> India-411014.{" "}
                  </p>

                  <p className="mt-4">
                    Factory :- Gat no: 620/2 <br /> Near High Cliff Industrial
                    Estate, <br /> Wagholi-rahu Road Kesnand, <br /> Pune-
                    412207
                  </p>

                  <p>
                    <a
                      href="tel:+91 9175447940"
                      className="text-decoration-none text-white"
                    >
                      +91 9156954601
                    </a>
                  </p>

                  <p>
                    <a
                      href="mailto:info@senwellexports.com"
                      className="text-decoration-none mt-4  text-white"
                    >
                      info@senwellexports.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <p className="text-center">
              &copy; {new Date().getFullYear()} Senwell Exports Private Limited
              |{" "}
              <Link className="ExportPrivacy" to="/privacy-policy">
                Privacy-Policy
              </Link>{" "}
              | All Rights Reserved.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Footer;
