import React from "react";
import { Link, useParams } from "react-router-dom";
import ProductData from "../../../../../Products.json";
import "./InnerCable.css";

const InnerCable = () => {
  const { typeId } = useParams();
  const AccessrSection = ProductData.sections.find(
    (section) => section.id == 2
  );

  const relevantData =
    AccessrSection.products[4] || [].find((item) => item.id === 205);
  const CableData = relevantData.CableProduct[0].BrassCopperProduct;

  const InnerCableData = CableData.filter(
    (product) => product.type_id == typeId
  );

  return (
    <div>
      <section className="innerCable-Section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="brass-subheader-content">
                <h1>{InnerCableData[0].type_name}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <h3 className="my-5">{InnerCableData[0].type_name}</h3>
            {InnerCableData[0].innerP.map((item, index) => (
              <div key={index} className="col-lg-3 col-md-6">
                <div className="service-block-3 mb-xl-30">
                  <div className="PCard">
                    <div className="service-image animate-img">
                      <img
                        src={item.img}
                        alt={item.type_name}
                        className="inner-fast-img"
                      />
                    </div>

                    <div className="card-body">
                      <h4 className="card-title">{item.Name}</h4>

                      <Link
                        to={`/Inner-cableTies-product/${typeId}/${item.type_id}`}
                        className="seview"
                      >
                        <button>View Details</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default InnerCable;
